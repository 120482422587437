import React from 'react'
import { getCustomStyleObject, MarginProps, PaddingProps } from 'src/components/Common/Styles'
import { CSSProps } from 'src/components/Common/Text'
import { Colors } from 'src/constants/colors'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { mobileQuery } from 'src/pages/public/common'

export enum BoxShadow {
  secondary = '0px 4px 4px rgba(0, 0, 0, 0.1)',
}

interface CardProps extends React.HTMLAttributes<HTMLElement> {
  bottomSeparator?: boolean
  myRef?: any
}

export type ContainerDivProps = CardProps & PaddingProps & MarginProps & CSSProps

const Div = styled.div``

/*
  This is a workaround to eliminate the Unknown HTML props passed from parent component
  to prevent React from giving console errors/warnings
  As styled-components handles the unknown HTML props as mentioned in doc
  https://styled-components.com/docs/basics#passed-props
*/

export const ContainerDiv: React.FC<ContainerDivProps> = ({ children, style, ...props }) => (
  <Div
    style={{
      textAlign: 'center',
      borderBottom: props.bottomSeparator ? '1px solid #e8e8e8' : 'unset',
      ...(props as CSSProps),
      ...getCustomStyleObject(props),
      ...(style as CSSProps),
    }}
    ref={props.myRef}
    {...props}
  >
    {children}
  </Div>
)

export const FjCard: React.FC<ContainerDivProps> = (props) => {
  const isMobile = useMediaQuery(mobileQuery)
  return (
    <ContainerDiv
      border={`1px solid ${Colors.sharkOpacity10}`}
      borderRadius="16px"
      padding={isMobile ? '12px' : '18px'}
      backgroundColor={Colors.white}
      {...props}
    />
  )
}
