import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FilterDropdownButton } from 'src/components/Common/Button'
import { AutoCompleteOption } from 'src/components/Common/FjFilter'
import FjPopover, { FjPopoverContent } from 'src/components/Common/FjPopover'
import { User } from 'src/models/User'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { sharedDataStore } from 'src/store/DataStore'

type ManagerFilterProps = {
  clearable?: boolean
  show?: boolean
  value?: string
  onChange: (managerId: string, managerName: string) => void
}

export const ManagerFilter = ({ clearable = true, show = true, value, onChange }: ManagerFilterProps) => {
  const [managerOptions, setManagerOptions] = useState<AutoCompleteOption[]>([])
  const [initialManagerOptions, setInitialManagerOptions] = useState<AutoCompleteOption[]>([])
  const [selectedManagerOption, setSelectedManagerOption] = useState<AutoCompleteOption>()

  const selectDefaultManager = useCallback(() => {
    const defaultManagerUser = sharedDataStore.user.getDefaultManager()
    setSelectedManagerOption(convertUserToAutoCompleteOption(defaultManagerUser))
    onChange(defaultManagerUser?.id, defaultManagerUser?.fullName)
  }, [onChange])

  const getSelectedManager = useCallback(async () => {
    const selectedUserFromOptions = [...initialManagerOptions, ...managerOptions].find((option) => option.id === value)
    if (selectedUserFromOptions) {
      setSelectedManagerOption(selectedUserFromOptions)
      return
    }
    try {
      const user = await User.get(value, undefined, undefined, { has_reports: true, fields: 'id,full_name' })
      setSelectedManagerOption(convertUserToAutoCompleteOption(user))
    } catch (err) {
      selectDefaultManager()
      sharedAppStateStore.handleError(err)
    }
  }, [value, selectDefaultManager, initialManagerOptions, managerOptions])

  const fetchManagerOptions = async (search: string) => {
    try {
      const data = await User.list({ search, has_reports: true, is_active: true, fields: 'id,full_name' })
      const options = data.data.map(convertUserToAutoCompleteOption)
      if (initialManagerOptions.length === 0 && !search) {
        setInitialManagerOptions(options)
      }
      setManagerOptions(options)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  useEffect(() => {
    fetchManagerOptions('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (value) {
      getSelectedManager()
    } else {
      setSelectedManagerOption(undefined)
    }
  }, [getSelectedManager, value])

  const convertUserToAutoCompleteOption = (user: User): AutoCompleteOption => ({
    key: user.id,
    id: user.id,
    value: user.fullName,
    label: user.fullName,
  })

  const showFilter = useMemo(() => {
    return show && initialManagerOptions.length > 0
  }, [initialManagerOptions, show])

  if (!showFilter) return null

  return (
    <FjPopover
      content={
        <FjPopoverContent
          options={managerOptions}
          values={selectedManagerOption ? [selectedManagerOption] : []}
          onSearch={(value: string) => fetchManagerOptions(value)}
          addFilterOption={(option) => onChange(option.id, option.label)}
        />
      }
    >
      <FilterDropdownButton
        selected={!!selectedManagerOption}
        title={selectedManagerOption ? `Manager | ${selectedManagerOption.label}` : 'Manager'}
        onClear={clearable ? () => onChange(undefined, undefined) : undefined}
      />
    </FjPopover>
  )
}
