import React from 'react'
import { Tag, Tooltip } from 'antd'
import { TagProps } from 'antd/lib/tag'
import { Colors } from 'src/constants/colors'
import { mobileQuery } from 'src/pages/public/common'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import BuildingIcon from 'src/assets/icons/building.svg'

interface FjTagProps extends TagProps {
  optionKey?: string
  text: string
  closable?: boolean
  round?: boolean
  onTagClose?(key: string, value: string): void
}

export const FjTag: React.FC<FjTagProps> = ({
  closable,
  optionKey,
  onTagClose,
  text,
  round,
  style,
  ...props
}: FjTagProps) => {
  return (
    <Tag
      style={{ marginTop: '5px', borderRadius: round ? '30px' : 'unset', ...style }}
      closable={closable}
      onClose={() => onTagClose(optionKey, text)}
      {...props}
    >
      {text}
    </Tag>
  )
}

FjTag.defaultProps = {
  closable: true,
}
interface PlainTagProps {
  text?: string
  style?: React.CSSProperties
  icon?: string
}

const StyledFjTag = styled(FjTag)<{ isMobile?: boolean }>`
  && {
    padding: ${({ isMobile }) => (isMobile ? '1px 8px 1px 8px !important' : '4px 12px 4px 12px !important')};
    border: none;
    background-color: ${Colors.zumthor};
    font-size: small;
    font-weight: bold;
    white-space: nowrap;
    text-transform: uppercase;
    width: fit-content;
    margin-top: 0 !important;
    border-radius: 26px;
    height: min-content;
    min-width: ${({ isMobile }) => (isMobile ? '80px' : '150px')};
  }
`

export const InternalUseTag = ({ style }: PlainTagProps) => {
  const isMobile = useMediaQuery(mobileQuery)
  const title = 'Internal Use Only'
  if (isMobile) {
    return (
      <Tooltip placement="bottom" title={title} arrowPointAtCenter trigger={['click']}>
        <img width="16px" alt="internal-use-icon" src={BuildingIcon} style={{ marginRight: '12px' }} />
      </Tooltip>
    )
  }

  return <StyledFjTag isMobile={isMobile} style={style} text={title} closable={false} />
}
