import React from 'react'
import { FjCard } from 'src/components/Common/Cards'
import emptyContentIcon from 'src/assets/img/empty-content.svg'
import { FjText } from 'src/components/Common/Text'
import { Colors } from 'src/constants/colors'

export const FeatureDisabled = () => {
  return (
    <FjCard
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="64px"
      border={`dashed 2px ${Colors.sharkOpacity10}`}
    >
      <img src={emptyContentIcon} alt="Disabled for org" />
      <FjText fontSize="20px" fontWeight="bold" color={Colors.shark} marginTop="40px">
        This feature is currently disabled for your organization.
      </FjText>
      <FjText fontSize="16px" fontWeight="bold500" color={Colors.tapa} marginTop="8px">
        Please reach out to your{' '}
        <FjText color={Colors.cornflowerBlue} fontWeight="bold">
          <a href="mailto:support@flockjay.com">CSM</a>
        </FjText>{' '}
        to enable it.
      </FjText>
    </FjCard>
  )
}
