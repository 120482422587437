import React from 'react'
import styled from 'styled-components'
import { Dropdown, DropdownProps, DropDownProps } from 'antd'
import { FjText, Icon, TextCustomProps, HoverText, FjAvatar } from 'src/components/Common'
import { PaddingProps, MarginProps } from 'src/components/Common/Styles'
import { Colors } from 'src/constants/colors'
import downCaret from 'src/assets/icons/DownCaret/DownCaret.svg'
import { User } from 'src/models/User'
import { mobileQuery } from 'src/pages/public/common'
import { useMediaQuery } from 'react-responsive'

interface DefaultDropDownProps extends DropDownProps {
  dropdownType?: 'basic' | 'dark-navy' | 'orange' | 'teal' | 'nav'
  textColor?: string
  hoverText?: boolean
  showArrow?: boolean
  user: User
}

export const BasicDropDown = styled(Dropdown)`
  background-color: ${Colors.white};
  :hover {
    cursor: pointer;
  }
`

export const DarkNavyDropDown = styled(BasicDropDown)`
  background-color: ${Colors.shark};
`

export const TealDropDown = styled(BasicDropDown)`
  background-color: ${Colors.robinsEggBlue};
`

export const NavDropDown = styled(BasicDropDown)`
  background-color: transparent;
`

type IDefaultDropDownProps = DefaultDropDownProps & PaddingProps & MarginProps & TextCustomProps

export const DefaultDropDown: React.FC<IDefaultDropDownProps> = (props: IDefaultDropDownProps) => {
  let CompName = BasicDropDown
  let color = Colors.shark
  const displayPic = props.user.displayPic
  const dropdownTitle = props.user.fullName

  if (!props.dropdownType || props.dropdownType === 'basic') {
    color = props.textColor ? props.textColor : Colors.outerSpace
  } else {
    switch (props.dropdownType) {
      case 'dark-navy':
        CompName = DarkNavyDropDown
        break
      case 'nav':
        CompName = NavDropDown
        break
      default:
        CompName = BasicDropDown
        color = Colors.outerSpace
        break
    }
  }
  return (
    <CompName menu={props.menu} trigger={props.trigger || ['click']} placement={props.placement}>
      {props.hoverText ? (
        <HoverText color={Colors.shark} display="inline" fontSize={props.fontSize}>
          {displayPic ? <FjAvatar src={displayPic} style={{ marginRight: '5px' }} author={props.user} /> : null}
          {dropdownTitle}
          {props.showArrow ? <Icon src={downCaret} marginLeft /> : null}
        </HoverText>
      ) : (
        <FjText fontSize={props.fontSize || 'small'} paddingVertical="5px" paddingHorizontal="5px" color={color}>
          {displayPic ? <Icon src={displayPic} marginRight /> : null}
          {dropdownTitle}
          {props.showArrow ? <Icon src={downCaret} marginLeft /> : null}
        </FjText>
      )}
    </CompName>
  )
}

export const FjDropdown = ({ children, ...props }: DropdownProps) => {
  const isMobile = useMediaQuery(mobileQuery)
  return (
    <Dropdown trigger={[isMobile ? 'click' : 'hover']} {...props}>
      {children}
    </Dropdown>
  )
}
