import { observer } from 'mobx-react'
import React, { useEffect, useMemo, useState } from 'react'
import { FilterDropdownButton } from 'src/components/Common/Button'
import FjPopover, { FjPopoverContent } from 'src/components/Common/FjPopover'
import { sharedDataStore } from 'src/store/DataStore'
import { getIntersection } from 'src/utils/format'
import { AutoCompleteOption } from 'src/components/Common/FjFilter'
import { LAST_SELECTED_GROUP_ID_KEY } from 'src/models/User'

type GroupSelectProps = {
  clearable?: boolean
  value?: string
  onChange: (groupId: string) => void
  limitToGroupIds?: string[]
}

export const GroupSelect: React.FC<GroupSelectProps> = observer(
  ({ clearable, value, onChange, limitToGroupIds }: GroupSelectProps) => {
    const [selectedGroupId, setSelectedGroupId] = useState(value)

    useEffect(() => {
      updateSelectedGroupIdStateAndStore(value)
    }, [value])

    const groupOptions: AutoCompleteOption[] = useMemo(() => {
      const commonGroupIds = limitToGroupIds
        ? getIntersection(sharedDataStore.user.groupIds, limitToGroupIds)
        : sharedDataStore.user.groupIds
      const groupsToShow = sharedDataStore.user.groups.filter((group) => commonGroupIds.includes(group.id))
      return groupsToShow.map((group) => ({ key: group.id, id: group.id, value: group.id, label: group.name }))
    }, [limitToGroupIds])

    const handleOptionSelect = (option: AutoCompleteOption) => {
      updateSelectedGroupIdStateAndStore(option.id)
      onChange(option.id)
    }

    const handleRemoveFilterOption = (option: AutoCompleteOption) => {
      setSelectedGroupId(null)
      onChange(null)
    }

    const handleClear = () => {
      setSelectedGroupId(null)
      onChange(null)
    }

    const updateSelectedGroupIdStateAndStore = (groupId: string) => {
      if (groupId) localStorage.setItem(LAST_SELECTED_GROUP_ID_KEY, groupId)
      setSelectedGroupId(groupId)
    }

    const selectedOption = groupOptions.find((option) => option.id === selectedGroupId)

    return (
      <FjPopover
        content={
          <FjPopoverContent
            options={groupOptions}
            values={selectedOption ? [selectedOption] : []}
            addFilterOption={handleOptionSelect}
            removeFilterOption={clearable ? handleRemoveFilterOption : undefined}
          />
        }
      >
        <FilterDropdownButton
          onClear={clearable ? handleClear : undefined}
          selected={!!selectedOption}
          title={selectedOption ? `Group | ${selectedOption.label}` : 'Group'}
        />
      </FjPopover>
    )
  }
)
