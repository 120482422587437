import moment from 'moment'
import { observable, makeObservable } from 'mobx'
import { DataObject } from './DataObject'
import { User } from 'src/models/User'
import { FeedPost } from 'src/models/FeedPost'
import { FeedComment } from 'src/models/FeedComment'
import { Prompt } from 'src/models/Prompt'
import { QueueItem } from 'src/models/QueueItem'
import { Paths } from 'src/constants/navigation'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { FeedPlaylist } from 'src/models/FeedPlaylist'
import { LearningPath } from 'src/models/LearningPath'
import { Course } from 'src/models/Course'
import { Call } from 'src/models/Call'
import { Asset } from 'src/models/Asset'
import { getFeedContentTargetLink } from 'src/utils/content'
import { AssignmentSubmission } from 'src/models/AssignmentSubmission'
import { CoachingMoment } from 'src/models/CoachingMoment'
import { SharedContent } from 'src/models/SharedContent'
import { DealRoom } from 'src/models/DealRoom'

const mapDataToTarget = (data: any) => {
  if (data.targetType === 'prompt') return Prompt.fromData(data.target)
  else if (data.targetType === 'feedcomment') return FeedComment.fromData(data.target)
  else if (data.targetType === 'userqueueitem') return QueueItem.fromData(data.target)
  else if (data.targetType === 'feedpost') return FeedPost.fromData(data.target)
  else if (data.targetType === 'salesforceopportunity') return SfOpportunity.fromData(data.target)
  else if (data.targetType === 'playlist') return FeedPlaylist.fromData(data.target)
  else if (data.targetType === 'course') return Course.fromData(data.target)
  else if (data.targetType === 'learningpath') return LearningPath.fromData(data.target)
  else if (data.targetType === 'call') return Call.fromData(data.target)
  else if (data.targetType === 'asset') return Asset.fromData(data.target)
  else if (data.targetType === 'assignmentsubmission') return AssignmentSubmission.fromData(data.target)
  else if (data.targetType === 'coachingmoment') return CoachingMoment.fromData(data.target)
  else if (data.targetType === 'sharedcontent') return SharedContent.fromData(data.target)
}

type NotificationVerb =
  | 'mentioned'
  | 'commented'
  | 'prompted'
  | 'like'
  | 'sos posted'
  | 'follow_tag_post_created'
  | 'follow_tag_object_created'
  | 'queue_updated'
  | 'sf_opportunity_won'
  | 'incomplete_queue_items'
  | 'queue_items_due'
  | 'manager_queue_item_reminder'
  | 'assignment_graded'
  | 'coaching_moment_created'
  | 'coaching_moment_assigned'
  | 'shared_content_viewed'
  | 'shared_content_downloaded'
  | 'expiring_contents'
  | 'assignment_submitted'
  | 'assignment_resubmission_requested'
  | 'ai_tags_suggested'
  | 'shared_content_activity'
  | 'notify_retake_content'
  | 'grade_assignment_reminder'

export class Notification extends DataObject {
  static OVERRIDE_MAPPINGS = {
    actor: (data) => (data.actor ? User.fromData(data.actor) : undefined),
    target: mapDataToTarget,
    recipient: (data) => (data.recipient ? User.fromData(data.recipient) : undefined),
  }
  static apiEndpoint: string = '/feed/notifications/'

  actor: User
  verb: NotificationVerb
  targetType:
    | 'feedpost'
    | 'feedcomment'
    | 'prompt'
    | 'userqueueitem'
    | 'playlist'
    | 'course'
    | 'learningpath'
    | 'call'
    | 'salesforceopportunity'
    | 'asset'
    | 'assignmentsubmission'
    | 'coachingmoment'
    | 'sharedcontent'
  target:
    | FeedPost
    | FeedComment
    | Prompt
    | QueueItem
    | SfOpportunity
    | FeedPlaylist
    | Course
    | LearningPath
    | Call
    | Asset
    | AssignmentSubmission
    | CoachingMoment
    | SharedContent
  copy: string
  @observable read: boolean
  recipient: User
  likeCount?: number
  createdAt: moment.Moment = moment()
  lastUpdated: moment.Moment = moment()

  constructor() {
    super()
    makeObservable(this)
  }

  getTargetPath = () => {
    if (this.target instanceof FeedComment) {
      if (this.target.postId) {
        return Paths.getClassroomPath({ postId: this.target.postId })
      } else if (this.target.assetId) {
        return Paths.getClassroomPath({ assetId: this.target.assetId })
      }
    } else if (this.target instanceof QueueItem) {
      return this.target.getLinkedObjectDetailPath()
    } else if (this.target instanceof SfOpportunity) {
      return Paths.getCreatePostPath(this.target.id)
    } else if (this.target instanceof AssignmentSubmission) {
      if (this.verb === 'assignment_submitted') {
        return Paths.getSubmissionPath(this.target.id)
      } else {
        return Paths.getSubmodulePath(this.target.courseId, this.target.submoduleId)
      }
    } else if (this.target instanceof SharedContent) {
      if (this.target.learningContent instanceof DealRoom)
        return Paths.getDealRoomPath({ id: this.target.learningContent.id, queryParams: { viewAnalytics: true } })
      return Paths.getClassroomPath({ assetId: this.target.learningContent.id, params: { viewAnalytics: true } })
    } else {
      return getFeedContentTargetLink(this.target, { isAuthoring: this.verb === 'coaching_moment_created' })
    }
  }
}
