// Given an array of objects with ids (idKey) and the labels (labelKey) to check for duplicates,
// this function will number all duplicate values of the labels [labelKey], incrementally
export const renameDuplicates = (arr: any[], idKey: string, labelKey: string) => {
  let idLabelMap = new Map<string, string>()
  for (let item of arr) {
    if (!idLabelMap.get(item[idKey])) idLabelMap.set(item[idKey], item[labelKey])
  }

  let labelCount: { [key in string]: number } = {}
  for (let [id, label] of idLabelMap) {
    if (!labelCount[label]) labelCount[label] = 1
    else {
      idLabelMap.set(id, `${label} (${labelCount[label]})`)
      labelCount[label] += 1
    }
  }

  return arr.map((item) => {
    return { ...item, [labelKey]: idLabelMap.get(item[idKey]) }
  })
}
