import React, { useCallback } from 'react'
import { RouteComponentProps } from 'react-responsive'
import { observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import { PublicFeedPage } from 'src/components/Page/Page'

import { sharedAppStateStore } from 'src/store/AppStateStore'

import { SharedContent } from 'src/models/SharedContent'
import { getQueryParam } from 'src/utils/urlParams'
import { AssetView } from 'src/components/Feed/FeedCard'
import {
  FjCard,
  ContainerDiv,
  FjText,
  FjAvatar,
  FjInput,
  FjFormItem,
  FjEmailInput,
  Anchor,
  DefaultButton,
  ErrorText,
} from 'src/components/Common'
import { Alert, Col, Row } from 'antd'
import { ViewTracker } from 'src/utils/Tracker'
import { Asset } from 'src/models/Asset'
import { DealRoom } from 'src/models/DealRoom'
import { DealRoomRenderer } from 'src/pages/DealRoomView'
import { FjModal } from 'src/components/Common/Modals'
import { SharedContentAssetViewModal } from 'src/components/Feed/SharedContentAssetViewModal'
import { sharedDataStore } from 'src/store/DataStore'
import { Author } from 'src/models/Author'
import { Form, Formik } from 'formik'
import { combineValidations, isEmail, isRequired } from 'src/utils/validation'
import { Lock } from 'react-feather'
import { ViewState, ViewWrapper } from 'src/components/Common/ViewWrapper'

type SharedContentViewState = ViewState | 'sharedContent' | 'sharedContentPassword' | 'sharedContentViewerInfo'

export const SharedContentAuthor: React.FC<{ author: Author }> = ({ author }) => {
  return (
    <ContainerDiv display="flex" alignItems="center" justifyContent="end" gap="10px">
      {!sharedAppStateStore.isMobile ? (
        <ContainerDiv display="flex" flexDirection="column">
          <FjText fontWeight="semi-bold" display="inline-block" fontSize="medium" textAlign="right">
            {author.fullName}
          </FjText>
          <Anchor href={`mailto:${author.email}`} type="tapaTapa" hoverUnderline>
            {author.email}
          </Anchor>
        </ContainerDiv>
      ) : null}
      <FjAvatar
        author={author}
        showPopover={sharedAppStateStore.isMobile}
        popoverProps={{ placement: 'bottomLeft' }}
        showEmail
        showWorkInfo={false}
      />
    </ContainerDiv>
  )
}

interface SharedContentEmbeddedModalAnchorProps extends React.PropsWithChildren {
  sharedContentId: string
  assetId: string
  password?: string
  trackingContext?: object
  style?: React.CSSProperties
  anchorProps?: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
}

export const SharedContentEmbeddedModalAnchor: React.FC<SharedContentEmbeddedModalAnchorProps> = ({
  sharedContentId,
  assetId,
  children,
  password,
  trackingContext = {},
  anchorProps,
}) => {
  const handleLinkClicked = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      sharedAppStateStore.sharedContentAssetViewModalProps = { sharedContentId, assetId, password, trackingContext }
    },
    [sharedContentId, assetId, password, trackingContext]
  )
  return (
    <a {...anchorProps} onClick={handleLinkClicked}>
      {children}
    </a>
  )
}

const SharedContentContentView: React.FC<{
  sharedContent: SharedContent
  updateFjEmbedLinksForAnonymousViewing?: boolean
  password?: string
  trackingContext?: object
}> = ({ sharedContent, updateFjEmbedLinksForAnonymousViewing, password, trackingContext = {} }) => {
  if (sharedContent.learningContent instanceof Asset) {
    return (
      <AssetView
        isSharedView
        asset={sharedContent.learningContent}
        hideDescription
        videoTrackingSource="shared_content"
        downloadTrackingContext={{ shared_content_id: sharedContent.id, ...trackingContext }}
      />
    )
  }

  if (sharedContent.learningContent instanceof DealRoom) {
    return (
      <DealRoomRenderer
        card={false}
        dealRoom={sharedContent.learningContent}
        updateFjEmbedLinksForAnonymousViewing={updateFjEmbedLinksForAnonymousViewing}
        password={password}
        trackingContext={trackingContext}
      />
    )
  }

  return null
}

@observer
export class SharedContentPage extends React.Component<RouteComponentProps> {
  @observable viewState: SharedContentViewState = 'initialLoad'
  @observable sharedContent: SharedContent
  @observable showPasswordError = false
  viewTrackingContext: object
  password?: string

  constructor(props: RouteComponentProps) {
    super(props)
    makeObservable(this)
    this.viewTrackingContext = SharedContent.getAnonViewerInfo() || {}
  }

  async componentDidMount() {
    this.fetchSharedContent()
  }

  async componentDidUpdate(prevProps: RouteComponentProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.fetchSharedContent()
    }
  }

  fetchSharedContent = async (showPasswordError = false) => {
    try {
      this.viewState = 'loading'
      this.showPasswordError = showPasswordError
      const sharedContentId = getQueryParam('linkId')

      if (!this.password) this.password = SharedContent.getAccessKeyIfExists(sharedContentId)
      this.sharedContent = await SharedContent.getPublicSharedContent(sharedContentId, this.password)
      if (this.password) SharedContent.saveAccessKeyToLocalStorage(sharedContentId, this.password)

      if (this.sharedContent.requireViewerInfo && !this.viewTrackingContextValid())
        this.viewState = 'sharedContentViewerInfo'
      else this.viewState = 'sharedContent'
    } catch (err) {
      if (err.response.status === 401) {
        this.viewState = 'sharedContentPassword'
      } else {
        this.viewState = 'error'
        sharedAppStateStore.handleError(err)
      }
    }
  }

  viewTrackingContextValid = () =>
    ['name', 'email'].reduce((valid, key) => valid && !!this.viewTrackingContext[key], true)

  handlePasswordSubmit = (data: any) => {
    this.password = data['password']
    this.fetchSharedContent(true)
  }

  handleViewerInfoSubmit = (data: any) => {
    this.viewTrackingContext = { ...data }
    SharedContent.saveAnonViewerInfo({ ...this.viewTrackingContext })
    this.viewState = 'sharedContent'
  }

  render() {
    const { sharedContent, viewState } = this
    return (
      <PublicFeedPage
        hideSidebar
        navbarProps={{
          wordMarkUrl: sharedContent?.author.company?.logoUrl,
          rightComponent: sharedContent?.author ? <SharedContentAuthor author={sharedContent.author} /> : null,
          showLogo: this.viewState === 'error' || !!sharedContent,
        }}
      >
        <FjModal
          maskClosable
          open={!!sharedAppStateStore.sharedContentAssetViewModalProps}
          onCancel={() => (sharedAppStateStore.sharedContentAssetViewModalProps = undefined)}
          footer={null}
          width="1236px"
          destroyOnClose
        >
          <SharedContentAssetViewModal {...sharedAppStateStore.sharedContentAssetViewModalProps} />
        </FjModal>
        <Row justify="center">
          <Col xs={24} lg={20}>
            <ViewWrapper
              viewState={this.viewState}
              errorStateProps={{
                subtitleLine1:
                  'This link may have expired. Please contact whoever shared this link with you to access this content.',
                imgWidth: '40%',
              }}
            >
              {viewState === 'sharedContentPassword' ? (
                <FjCard width="450px" margin="auto">
                  <Formik initialValues={{ password: this.password }} onSubmit={this.handlePasswordSubmit}>
                    <Form>
                      <Lock />
                      <FjText display="block" fontWeight="semi-bold" fontSize="22px" marginBottom>
                        This content is protected
                      </FjText>
                      <FjText display="block" marginBottom>
                        To view, please enter the password
                      </FjText>
                      <FjFormItem name="password" validate={isRequired}>
                        <FjInput
                          name="password"
                          type="password"
                          placeholder="Password"
                          onChange={() => (this.showPasswordError = false)}
                        />
                        {/* Ideally we'd set the error on this field but can't consistently get a reference to this formik form so have to set it manually */}
                        {this.showPasswordError && <ErrorText>Incorrect Password</ErrorText>}
                      </FjFormItem>
                      <DefaultButton type="submit" buttonType="primary" title="Continue" width="100%" />
                    </Form>
                  </Formik>
                </FjCard>
              ) : viewState === 'sharedContentViewerInfo' ? (
                <FjCard width="450px" margin="auto">
                  <Formik
                    initialValues={{ name: undefined, email: undefined, ...this.viewTrackingContext }}
                    onSubmit={this.handleViewerInfoSubmit}
                  >
                    <Form>
                      <FjText display="block" fontWeight="semi-bold" fontSize="22px" marginBottom>
                        Enter your information below
                      </FjText>
                      <FjFormItem fieldtitle="Name*" name="name" validate={isRequired}>
                        <FjInput name="name" placeholder="Name" />
                      </FjFormItem>
                      <FjFormItem fieldtitle="Email*" name="email" validate={combineValidations(isRequired, isEmail)}>
                        <FjEmailInput name="email" placeholder="Email" />
                      </FjFormItem>
                      <DefaultButton type="submit" buttonType="primary" title="Continue" width="100%" />
                    </Form>
                  </Formik>
                </FjCard>
              ) : viewState === 'sharedContent' ? (
                <FjCard textAlign="left">
                  <ContainerDiv id={sharedContent.learningContent.getTrackingElementId()} textAlign="left">
                    {sharedContent.expiryDate ? (
                      <Alert
                        type="info"
                        style={{ marginBottom: '10px' }}
                        message={
                          <div>
                            This link will expire in{' '}
                            <FjText fontWeight="semi-bold">{sharedContent.expiryDate.fromNow(true)}</FjText>
                          </div>
                        }
                      />
                    ) : null}
                    <ViewTracker
                      observerThreshold={0}
                      obj={sharedContent.learningContent}
                      source="shared_content"
                      context={{ shared_content_id: sharedContent.id, ...this.viewTrackingContext }}
                    />

                    <SharedContentContentView
                      sharedContent={sharedContent}
                      updateFjEmbedLinksForAnonymousViewing={sharedDataStore.user.isAnonymous()}
                      password={this.password}
                      trackingContext={{ ...this.viewTrackingContext }}
                    />
                  </ContainerDiv>
                </FjCard>
              ) : null}
            </ViewWrapper>
          </Col>
        </Row>
      </PublicFeedPage>
    )
  }
}
