import React, { Suspense, lazy } from 'react'
import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { Send } from 'react-feather'
import { Prompt } from 'src/models/Prompt'
import {
  FjFormItem,
  DefaultButton,
  ContainerDiv,
  GroupSelector,
  TagSelector,
  FormHeaderText,
  FjInput,
  Loader,
  FjCheckBox,
  HubSelector,
} from 'src/components/Common'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { observer } from 'mobx-react'
import { observable, makeObservable } from 'mobx'
import { sharedDataStore } from 'src/store/DataStore'
import { combineValidations, isMaxLength, isRequired } from 'src/utils/validation'
import { showNotification } from 'src/hoc/Notification'
import { getFeedContentTargetLink } from 'src/utils/content'
import { formatDateToPST, truncate } from 'src/utils/format'
import { ExpirationDateField } from 'src/components/Feed/ExpirationDateField'
import { Col, Row } from 'antd'
import { UserSelect } from 'src/components/Feed/UserSelect'

const FJCKEditor = lazy(() => import('src/components/Common/FJCKEditor'))

export interface PromptFormProps {
  prompt?: Prompt
  onSuccess(prompt: Prompt): void
  onDelete?(prompt: Prompt): void
  onCancel?(): void
  isPromptUpdated?: boolean
}

@observer
export class PromptForm extends React.Component<PromptFormProps> {
  formikRef: any
  @observable showDeleteModal = false

  constructor(props: PromptFormProps) {
    super(props)
    makeObservable(this)
    this.formikRef = React.createRef()
  }

  handleSubmit = async (data: any) => {
    try {
      const { question, description, isPublic, tags, groupIds, hubIds, expiryDate, hideResponseFromFeed, authorId } =
        data
      const prompt = this.props.prompt || new Prompt()
      await sharedAppStateStore.wrapAppLoading(
        prompt.save({
          question,
          description,
          isPublic,
          tags: tags || [],
          groupIds: groupIds || [],
          hubIds: hubIds || [],
          expiryDate: formatDateToPST(expiryDate),
          hideResponseFromFeed,
          authorId,
        })
      )
      showNotification({
        message: `${truncate(prompt.title, 70)} was ${this.props.prompt ? 'updated' : 'created'} successfully!`,
        duration: 10,
        target: getFeedContentTargetLink(prompt),
        clickToView: true,
      })
      sharedDataStore.localSettings.hideResponseFromFeed = hideResponseFromFeed
      if (!this.props.prompt && sharedDataStore.user.isFaasAdminOrManager() && prompt.groupIds.length) {
        const hideAddToQueueModal = () => (sharedAppStateStore.addToQueueModalProps = undefined)

        sharedAppStateStore.addToQueueModalProps = undefined
        sharedAppStateStore.addToQueueModalProps = {
          onSuccess: hideAddToQueueModal,
          item: prompt,
          onCancel: hideAddToQueueModal,
        }
      }

      this.props.onSuccess(prompt)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  handleDelete = async () => {
    try {
      const { prompt } = this.props
      await sharedAppStateStore.wrapAppLoading(prompt.delete())
      if (this.props.onDelete) this.props.onDelete(prompt)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  getInitialValues = () => {
    let { question, description, tags, groupIds, hubIds, expiryDate, hideResponseFromFeed, author } =
      this.props.prompt || new Prompt()

    return {
      question,
      description,
      tags,
      groupIds,
      hubIds,
      isPublic: false,
      expiryDate,
      hideResponseFromFeed: this.props.prompt
        ? hideResponseFromFeed
        : sharedDataStore.localSettings.hideResponseFromFeed,
      authorId: author?.id ?? sharedDataStore.user.id,
    }
  }

  onDelete = async () => {
    const { prompt } = this.props
    sharedAppStateStore.deleteDialogProps = {
      onConfirm: this.handleDelete,
      obj: prompt,
    }
  }

  render() {
    return (
      <ContainerDiv>
        <FormHeaderText
          heading={`${this.props.prompt ? 'Edit' : 'Create'} prompt`}
          subHeading="Ask your team a thought-provoking question about their work or routine to spark interesting conversations."
        />
        <Formik
          innerRef={this.formikRef}
          initialValues={this.getInitialValues()}
          onSubmit={this.handleSubmit}
          enableReinitialize
        >
          <Form>
            <FjFormItem
              name="question"
              fieldtitle="Title / Question*"
              validate={combineValidations(isRequired, isMaxLength(100))}
            >
              <FjInput
                name="question"
                placeholder="Title / Question"
                onChange={(e) => sharedAppStateStore.setPromptIsUpdated(e.currentTarget.value)}
              />
            </FjFormItem>
            <FjFormItem name="description" fieldtitle="Prompt Content" className="content-editor">
              <Suspense fallback={<Loader />}>
                <FJCKEditor
                  name="description"
                  placeholder="Add additional context, instructions, or examples here"
                  showRecordVideoBtn
                  includeMentions
                />
              </Suspense>
            </FjFormItem>
            <Row gutter={16}>
              <Col lg={12} md={24}>
                <GroupSelector name="groupIds" fieldTitle="Groups" excludePublicUserGroup showTooltip />
              </Col>
              <Col lg={12} md={24}>
                <TagSelector />
              </Col>
            </Row>
            <ExpirationDateField />
            <Row gutter={16}>
              {sharedDataStore.user.isFaasAdmin() ? (
                <Col lg={12} md={24}>
                  <FjFormItem name="hubIds" fieldtitle="Hubs">
                    <HubSelector name="hubIds" />
                  </FjFormItem>
                </Col>
              ) : null}
              {!sharedDataStore.user.isFaasPartner() ? (
                <Col lg={12} md={24}>
                  <FjFormItem fieldtitle="Author (Owner)*" name="authorId" validate={isRequired}>
                    <UserSelect
                      name="authorId"
                      placeholder="Author"
                      initialLabel={this.props.prompt?.author?.fullName ?? sharedDataStore.user.fullName}
                      queryParams={{ access_role: 'admin,manager,standard' }}
                    />
                  </FjFormItem>
                </Col>
              ) : null}
            </Row>
            <FjFormItem name="hideResponseFromFeed">
              <ContainerDiv display="flex" alignItems="center" gap={8} justifyContent="start">
                <FjCheckBox name="hideResponseFromFeed">Hide Responses from Feed</FjCheckBox>
              </ContainerDiv>
            </FjFormItem>
            <ContainerDiv display="flex" justifyContent="flex-end" textAlign="right">
              {this.props.prompt && <DefaultButton clicked={this.onDelete} buttonType="destructive" title="Delete" />}
              <DefaultButton
                type="submit"
                buttonType="primary"
                image={<Send size={16} />}
                title={this.props.prompt ? 'Save' : 'Post'}
                style={{ marginLeft: '10px' }}
              />
            </ContainerDiv>
          </Form>
        </Formik>
      </ContainerDiv>
    )
  }
}
