import React from 'react'
import { ContainerDiv, FjText, InternalUseTag } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { Asset } from 'src/models/Asset'
import { CoachingMoment } from 'src/models/CoachingMoment'
import { Hub } from 'src/models/Hub'
import { Page } from 'src/models/Page'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { LearningContent, getFeedContentTargetLink, getFeedContentTitle } from 'src/utils/content'

type ContentTitleProps = {
  obj: LearningContent | Hub | Page | CoachingMoment
}

export const ContentTitle = ({ obj }: ContentTitleProps) => {
  const isAsset = obj instanceof Asset
  return (
    <ContainerDiv
      cursor="pointer"
      textAlign="left"
      onClick={() => sharedAppStateStore.navigate(getFeedContentTargetLink(obj), false, { retain: true })}
      style={{ textDecoration: 'none' }}
      display="flex"
      alignItems="center"
      gap="12px"
    >
      <FjText
        textAlign="left"
        fontSize="clamp(0.8125rem, 0.2656rem + 1.75vw, 1.25rem)"
        lineHeight="30px"
        fontWeight="bold"
        color={Colors.chambray}
      >
        {getFeedContentTitle(obj)}
      </FjText>
      {isAsset && obj.isInternal ? <InternalUseTag /> : null}
    </ContainerDiv>
  )
}
