import { Tooltip, Typography } from 'antd'
import { TextProps as TypographyTextProps } from 'antd/lib/typography/Text'
import { TitleProps } from 'antd/lib/typography/Title'
import React, { ReactNode } from 'react'
import Info from 'src/assets/icons/Info.svg'
import { ContainerDiv, Icon, MarginProps, PaddingProps } from 'src/components/Common'
import { getCustomStyleObject, TextSizeOption, TextWeightOption } from 'src/components/Common/Styles'
import { Colors } from 'src/constants/colors'
import styled from 'styled-components'

export interface TextCustomProps {
  fontSize?: TextSizeOption | string
  fontWeight?: TextWeightOption
  highlight?: string
  error?: boolean
  hover?: string
}

const TypographyBasePropKeys = [
  'title',
  'editable',
  'copyable',
  'type',
  'disabled',
  'ellipsis',
  'code',
  'mark',
  'underline',
  'delete',
  'strong',
]

const BasicDivProps = [
  'className',
  'onMouseEnter',
  'onMouseLeave',
  'onTouchStart',
  'onFocus',
  'onMouseDown',
  'onContextMenu',
  'onClick',
  'onBlur',
]

const FjTextBaseProps = [...TypographyBasePropKeys, ...BasicDivProps]

const TypographyTitlePropKeys = [...FjTextBaseProps, 'level']

const composeProps = (props: object, keys: string[]) =>
  keys.reduce((acc, cur) => {
    if (cur in props) acc[cur] = props[cur]
    return acc
  }, {})

export type CSSProps = Omit<
  React.CSSProperties,
  keyof PaddingProps | keyof MarginProps | keyof TextCustomProps | keyof TypographyTextProps | keyof TitleProps
>

type CustomCSSProps = CSSProps & PaddingProps & MarginProps & TextCustomProps & React.HTMLAttributes<HTMLElement>
export type FjTextProps = TypographyTextProps & CustomCSSProps & { rows?: number }

export const FjText: React.FC<FjTextProps> = React.forwardRef(({ children, style, rows, ...props }, ref) => (
  <Typography.Text
    style={{
      textAlign: 'center',
      ...(props as CSSProps),
      ...getCustomStyleObject(props),
      ...(style as CSSProps),
    }}
    {...composeProps(props, FjTextBaseProps)}
  >
    {rows ? (
      <span
        style={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: rows,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </span>
    ) : (
      children
    )}
  </Typography.Text>
))

export const HoverText = styled(FjText)`
  :hover {
    text-decoration: underline;
    cursor: pointer;
    color: ${Colors.robinsEggBlue};
  }
`

export const Heading: React.FC<CustomCSSProps & TitleProps> = ({ children, style, ...props }) => (
  <Typography.Title
    level={4}
    style={{
      lineHeight: '1.2',
      textAlign: 'center',
      ...(props as CSSProps),
      ...(style as CSSProps),
      ...getCustomStyleObject({ fontSize: 'medium', ...props }),
    }}
    {...composeProps(props, TypographyTitlePropKeys)}
  >
    {children}
  </Typography.Title>
)

export const PageTitle: React.FC<CustomCSSProps & TitleProps> = ({ children, ...props }) => (
  <Heading level={1} fontSize="extraLarge" fontWeight="semi-bold" {...props}>
    {children}
  </Heading>
)
interface FormHeaderTextProps {
  heading?: ReactNode
  subHeading?: ReactNode
  tiptitle?: string
}

export const FormHeaderText: React.FC<FormHeaderTextProps> = ({
  heading,
  subHeading,
  tiptitle,
}: FormHeaderTextProps) => (
  <ContainerDiv
    padding="0 24px 12px 24px"
    margin="0 -24px 24px"
    textAlign="left"
    borderBottom={`1px solid ${Colors.sharkOpacity10}`}
  >
    <PageTitle fontSize="20px" textAlign="left" fontWeight="semi-bold" color={Colors.black}>
      {heading}
    </PageTitle>
    {subHeading && (
      <ContainerDiv
        className="linked-content-description"
        textAlign="left"
        lineHeight="22px"
        marginTop
        style={{ fontSize: '12px' }}
      >
        {typeof subHeading === 'string' ? (
          <FjText color={Colors.tapa} fontSize="small" fontWeight="bold500">
            {subHeading}
          </FjText>
        ) : (
          subHeading
        )}
        {tiptitle && (
          <Tooltip placement="bottomLeft" title={tiptitle}>
            <Icon src={Info} marginLeft="5px" />
          </Tooltip>
        )}
      </ContainerDiv>
    )}
  </ContainerDiv>
)

export const ErrorText: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <FjText height="24px" display="block" textAlign="left" color={Colors.sunsetOrange} marginBottom="2px">
      {children}
    </FjText>
  )
}
