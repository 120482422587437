import { computed, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { FilterDropdownButton } from 'src/components/Common/Button'
import { AutoCompleteOption } from 'src/components/Common/FjFilter'
import FjPopover, { FjPopoverContent } from 'src/components/Common/FjPopover'
import { Asset } from 'src/models/Asset'
import { Course } from 'src/models/Course'
import { FeedPlaylist } from 'src/models/FeedPlaylist'
import { LearningPath } from 'src/models/LearningPath'
import { Prompt } from 'src/models/Prompt'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { renameDuplicates } from 'src/utils/chart'
import {
  LearningContentType,
  fetchContent,
  fetchContentById,
  getContentCategoryTitle,
  getFeedContentData,
  getFeedContentTypeName,
} from 'src/utils/content'
import { debounce } from 'src/utils/debounce'

type LearningContentFilterProps = {
  value?: string
  onChange: (contentId) => void
  contentType: LearningContentType
}

@observer
export class LearningContentFilter extends Component<LearningContentFilterProps> {
  @observable learningContentOptions: AutoCompleteOption[] = []
  @observable selectedContent: Course | FeedPlaylist | Prompt | LearningPath | Asset = undefined

  constructor(props: LearningContentFilterProps) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    this.getSelectedContent()
  }

  componentDidUpdate(prevProps: Readonly<LearningContentFilterProps>): void {
    if (prevProps.contentType !== this.props.contentType || prevProps.value !== this.props.value) {
      this.getSelectedContent()
    }
  }

  @computed get learningContentOptionValues() {
    return [
      {
        key: this.selectedContent?.id,
        id: this.selectedContent?.id,
        label: this.selectedContent?.title,
        value: this.selectedContent?.title,
      },
    ].filter(Boolean)
  }

  getSelectedContent = async () => {
    if (!this.props.value) {
      this.selectedContent = undefined
      return
    }

    try {
      const data = await fetchContentById(this.props.value, this.props.contentType)
      this.selectedContent = data
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  onContentDropdownClick = () => {
    if (this.learningContentOptions.length > 0) return
    this.contentSearch('')
  }

  contentSearch = debounce(async (value: string) => {
    try {
      let data
      const response = await fetchContent(this.props.contentType, {
        page: 1,
        sort_by: 'recent',
        ...(value !== '' && { search: value }),
      })
      data = response.data
      const contentResults: (Course | FeedPlaylist | Prompt | LearningPath | Asset)[] = getFeedContentData(
        data,
        this.props.contentType
      )
      let options = contentResults.map((data) => ({ key: data.id, id: data.id, value: data.title, label: data.title }))
      // antD AutoComplete uses value as the key, so we need to rename duplicate values/labels to view accurate data
      options = renameDuplicates(options, 'key', 'value')
      this.learningContentOptions = options.map((option) => {
        return { ...option, label: option.value }
      })
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }, 300)

  render() {
    return (
      <FjPopover
        content={
          <FjPopoverContent
            options={this.learningContentOptions}
            values={this.learningContentOptionValues}
            onSearch={this.contentSearch}
            addFilterOption={(option) => this.props.onChange(option.key)}
          />
        }
      >
        <FilterDropdownButton
          selected={!!this.selectedContent}
          title={
            this.selectedContent
              ? `${getFeedContentTypeName({ contentType: this.props.contentType, upperCase: true })} | ${
                  this.selectedContent?.title
                }`
              : `Search ${getContentCategoryTitle(this.props.contentType)}`
          }
          onClick={this.onContentDropdownClick}
          onClear={() => this.props.onChange(undefined)}
        />
      </FjPopover>
    )
  }
}
