import React from 'react'
import { observer } from 'mobx-react'
import { makeObservable, observable } from 'mobx'
import { Asset } from 'src/models/Asset'
import { SharedContent } from 'src/models/SharedContent'
import { ContentTitle } from 'src/components/Feed/ContentTitle'
import { ViewTracker } from 'src/utils/Tracker'
import { AssetView } from 'src/components/Feed/FeedCard'
import { clearFalseyValues } from 'src/utils/format'
import { ViewWrapper } from 'src/components/Common/ViewWrapper'

export interface ISharedContentAssetViewModalProps {
  sharedContentId: string
  assetId: string
  password?: string
  trackingContext?: object
}

@observer
export class SharedContentAssetViewModal extends React.Component<ISharedContentAssetViewModalProps> {
  @observable viewState: 'initialLoad' | 'loading' | 'idle' | 'notFound' = 'initialLoad'
  asset: Asset

  constructor(props) {
    super(props)
    makeObservable(this)
  }

  async componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(
    prevProps: Readonly<ISharedContentAssetViewModalProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (this.props.sharedContentId !== prevProps.sharedContentId || this.props.assetId !== prevProps.assetId) {
      this.fetchData()
    }
  }

  fetchData = async () => {
    try {
      this.viewState = 'loading'
      const { sharedContentId, assetId, password } = this.props
      const { learningContent } = await SharedContent.get(
        sharedContentId,
        undefined,
        undefined,
        clearFalseyValues({
          content_type: 'asset',
          object_id: assetId,
          access_key: password,
        })
      )
      if (!(learningContent instanceof Asset)) {
        this.viewState = 'notFound'
        return
      }
      this.asset = learningContent
      this.viewState = 'idle'
    } catch (err) {
      this.viewState = 'notFound'
    }
  }

  render() {
    const { sharedContentId, trackingContext = {} } = this.props

    return (
      <ViewWrapper viewState={this.viewState} errorStateProps={{ title: '', subtitleLine1: '' }}>
        {this.viewState === 'idle' ? (
          <div>
            <ViewTracker
              observerThreshold={0}
              obj={this.asset}
              source="shared_content"
              context={{ shared_content_id: sharedContentId, ...trackingContext }}
            />

            {/* PageRenderer shows the page title so only show asset title if not page so not to confuse user */}
            {!this.asset.page ? <ContentTitle obj={this.asset} /> : null}

            <AssetView
              asset={this.asset}
              hideDescription
              videoTrackingSource="shared_content"
              downloadTrackingContext={{ shared_content_id: sharedContentId, ...trackingContext }}
              mediaWatchTrackingContext={{ shared_content_id: sharedContentId, ...trackingContext }}
            />
          </div>
        ) : null}
      </ViewWrapper>
    )
  }
}
