import moment, { Moment } from 'moment'
import React, { Component, useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import { FjEvent } from 'src/models/FjEvent'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { sharedDataStore } from 'src/store/DataStore'
import { MediaTracker } from 'src/utils/Analytics'

const FEED_POST_SCROLLING_VIEW_THRESHOLD = 3000 // duration to wait before counting as a view (in milliseconds)

interface ITrackableObject {
  id: string
  getTrackingElementId: () => string
  getViewTrackingEventType: () => string
  getViewTrackingObjIdKey: () => string
  viewTrackingThresholdReached: (start: Moment) => boolean
}

interface ViewTrackerProps {
  obj: ITrackableObject
  source: string
  offsetDuration?: number
  observerThreshold?: number
  context?: object
}
export class ViewTracker extends Component<ViewTrackerProps> {
  observer: IntersectionObserver
  start?: moment.Moment
  delayTimerId?: number
  intervalId?: number
  event: FjEvent
  data: any = {}

  constructor(props: ViewTrackerProps) {
    super(props)
    /*
        Threshold is the percentage of the post has to be visible before it counts as visible.
        e.g. 0.5 means at least 50% of pixels of the target must be visible for callback to trigger
        0 means if anything is visible it will count, 1.0 means everything must be visible
      */
    const threshold = props.observerThreshold === undefined ? 0.25 : props.observerThreshold
    this.observer = new IntersectionObserver(this.observerCallback, { threshold })
  }

  componentDidMount() {
    window.addEventListener('blur', this.pauseTracking)
    window.addEventListener('focus', this.observe)
    this.observe()
  }

  componentDidUpdate(prevProps: ViewTrackerProps) {
    if (prevProps.obj.id !== this.props.obj.id) {
      this.endTracking()
      this.clearTimer()
      this.observer.disconnect()
    }
    if (this.props.obj.id) this.observe()
  }

  componentWillUnmount() {
    window.removeEventListener('blur', this.pauseTracking)
    window.removeEventListener('focus', this.observe)
    this.endTracking()
  }

  pauseTracking = () => {
    this.endTracking()
    this.clearTimer()
    this.observer.disconnect()
  }

  observe = () => {
    const { obj } = this.props
    this.observer.observe(document.querySelector(`#${obj.getTrackingElementId()}`))
  }

  observerCallback = ([entry]: IntersectionObserverEntry[]) => {
    const { offsetDuration } = this.props
    if (!this.delayTimerId && !this.start && entry.isIntersecting) {
      this.delayTimerId = window.setTimeout(
        () => {
          this.startTracking()
          this.clearTimer()
        },
        offsetDuration === undefined ? FEED_POST_SCROLLING_VIEW_THRESHOLD : offsetDuration
      )
    } else if (!entry.isIntersecting) {
      this.endTracking()
      this.clearTimer()
    }
  }

  saveViewEvent = async (data: any) => {
    const { obj } = this.props
    this.data = data
    if (obj.viewTrackingThresholdReached(this.start)) {
      this.event = this.event ? FjEvent.fromData({ ...this.event }) : new FjEvent()
      await this.event.save({
        eventType: obj.getViewTrackingEventType(),
        user: sharedDataStore.user.id,
        data: { ...this.data, sessionId: sharedAppStateStore.sessionId },
      })
      sharedDataStore.updateContextLearningPathProgess?.()
    }
  }

  startTracking = () => {
    const { obj, source, context } = this.props
    const { timings = [] } = this.data

    this.start = moment()
    timings.push({
      start_timestamp: this.start.toISOString(),
      end_timestamp: this.start.add(2, 'seconds').toISOString(),
    })
    this.saveViewEvent({
      source,
      timings,
      [obj.getViewTrackingObjIdKey()]: obj.id,
      ...(context || {}),
    })

    this.intervalId = window.setInterval(() => {
      this.updateTiming()
    }, 10000)
  }

  endTracking = () => {
    if (!this.start) return
    this.clearInterval()
    this.updateTiming()
    this.start = undefined
  }

  clearTimer = () => {
    if (this.delayTimerId) {
      window.clearTimeout(this.delayTimerId)
      this.delayTimerId = undefined
    }
  }

  clearInterval = () => {
    if (this.intervalId) {
      window.clearInterval(this.intervalId)
      this.intervalId = undefined
    }
  }

  updateTiming = () => {
    const { timings = [] } = this.data
    if (timings.length > 0) {
      timings[timings.length - 1]['end_timestamp'] = moment().toISOString()
      this.saveViewEvent({ ...this.data, timings })
    }
  }

  render() {
    return null
  }
}

interface MediaTrackerUIProps {
  obj: ITrackableObject
  context: object
  mediaFullyConsumed?: (i: number) => void
}

export type MediaTrackerUIHandle = {
  pauseMedia: () => void
  findMedia: () => any[] | NodeListOf<Element>
}

export const MediaTrackerUI = React.forwardRef<MediaTrackerUIHandle, MediaTrackerUIProps>(
  ({ obj, context, mediaFullyConsumed }, ref) => {
    const mediaTrackers = useRef([])

    const findMedia = useCallback(
      () => document.getElementById(obj.getTrackingElementId()).querySelectorAll('video, audio') || [],
      [obj]
    )

    const handleMediaPlaybackRateChange = (media: HTMLVideoElement | HTMLAudioElement) => {
      media.addEventListener('ratechange', (e) => {
        const newPlaybackRate = (e.target as HTMLVideoElement | HTMLAudioElement).playbackRate
        if (newPlaybackRate !== sharedDataStore.localSettings.playbackRate) {
          sharedDataStore.localSettings = { ...sharedDataStore.localSettings, playbackRate: newPlaybackRate }
        }
      })

      media.addEventListener(
        'play',
        () =>
          (media.playbackRate = sharedDataStore.localSettings.playbackRate
            ? sharedDataStore.localSettings.playbackRate
            : 1)
      )
    }

    const setupMediaTrackers = useCallback(() => {
      if (mediaTrackers.current.length) return
      const media = findMedia()
      if (!media.length) return
      for (let i = 0; i < media.length; i++) {
        const mediaElement = media[i]
        const document_id = mediaElement.getAttribute('data-document-id')
        mediaTrackers.current.push(
          new MediaTracker(mediaElement, { ...context, document_id }, undefined, undefined, () => mediaFullyConsumed(i))
        )
        handleMediaPlaybackRateChange(mediaElement)
      }
    }, [context, findMedia, mediaFullyConsumed])

    const pauseMedia = useCallback(() => {
      for (const tracker of mediaTrackers.current) {
        tracker.mediaElement.pause()
      }
    }, [])

    useImperativeHandle(
      ref,
      () => ({
        pauseMedia,
        findMedia,
      }),
      [pauseMedia, findMedia]
    )

    useEffect(() => {
      setupMediaTrackers()
    }, [setupMediaTrackers])

    return null
  }
)
