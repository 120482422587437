import React, { useState } from 'react'
import { ChevronDown, ChevronRight } from 'react-feather'
import { FjCard, ContainerDiv, FjText } from 'src/components/Common'
import { Colors } from 'src/constants/colors'

type CollapsibleWrapperProps = {
  title?: string
  icon?: React.ReactNode
  topRight?: React.ReactNode
  children: React.ReactNode
  initialCollapsed?: boolean
  onCollapseChange?: (collapsed: boolean) => void
}

export const CollapsibleWrapper: React.FC<CollapsibleWrapperProps> = ({
  title,
  icon,
  topRight,
  children,
  onCollapseChange,
  initialCollapsed = true,
}) => {
  const [collapsed, setCollapsed] = useState(initialCollapsed)

  const handleCollapseChange = () => {
    setCollapsed((collapsed) => !collapsed)
    onCollapseChange?.(!collapsed)
  }

  return (
    <FjCard padding="18px" textAlign="left" marginTop="25px">
      <ContainerDiv
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={handleCollapseChange}
        cursor="pointer"
      >
        <ContainerDiv display="flex" alignItems="center" gap={12}>
          {icon}
          {title ? (
            <FjText textAlign="left" fontSize="20px" fontWeight="bold">
              {title}
            </FjText>
          ) : null}
        </ContainerDiv>
        <ContainerDiv display="flex" gap={20} alignItems="center">
          {topRight}
          {!collapsed ? (
            <ChevronDown color={Colors.cottonSeed} size={20} style={{ cursor: 'pointer', minWidth: 24 }} />
          ) : (
            <ChevronRight color={Colors.cottonSeed} size={20} style={{ cursor: 'pointer', minWidth: 24 }} />
          )}
        </ContainerDiv>
      </ContainerDiv>
      {!collapsed && children}
    </FjCard>
  )
}
