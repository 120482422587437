import { Col, ColProps, Row } from 'antd'
import React from 'react'
import { ContainerDiv } from 'src/components/Common'
import { AuthorDisplay } from 'src/components/Common/AuthorDisplay'
import { User } from 'src/models/User'

type UserListProps = {
  users: User[]
  colProps?: ColProps
}

export const UserList = ({ users, colProps = { lg: 6, md: 8, sm: 12, xs: 24 } }: UserListProps) => {
  return (
    <ContainerDiv marginTop="8px" display="flex" flexDirection="column" gap={16}>
      {users.length > 0 ? (
        <Row gutter={[16, 16]}>
          {users.map((user, index) => (
            <Col key={index} {...colProps}>
              <AuthorDisplay author={user} avatarSize={24} />
            </Col>
          ))}
        </Row>
      ) : (
        'There are no users who have completed this module yet.'
      )}
    </ContainerDiv>
  )
}
