import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from 'ckeditor5/packages/ckeditor5-build-classic/build/ckeditor'
import { Field, FormikProps, FieldProps } from 'formik'
import { User } from 'src/models/User'
import { Paths } from 'src/constants/navigation'
import { FeedTag } from 'src/models/FeedTag'
import { uploadToS3 } from 'src/utils/S3Upload'
import { renameFile } from 'src/utils/renameFile'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { sharedDataStore } from 'src/store/DataStore'
import { ErrorText, VideoRecordButton } from 'src/components/Common'
import { baseURL } from 'src/network/FlockjayProvider'
import styled from 'styled-components'
import sanitizeHtml from 'sanitize-html'
import { containsJS } from 'src/utils/format'

const Wrapper = styled.div<{ showRecordVideoBtn: boolean }>`
  position: relative;
  ${(props) =>
    props.showRecordVideoBtn &&
    `.ck.ck-editor__main > .ck-editor__editable {
      padding-bottom: 3rem;
    }`}
`

const FILE_UPLOAD_SIZE_LIMIT = 1024 * 1024 * 1000 // 1GB

class FJCKEditorFileUploadAdapter {
  loader: any

  constructor(loader) {
    this.loader = loader
  }

  upload = async () => {
    const file: File = await this.loader.file
    if (file.size > FILE_UPLOAD_SIZE_LIMIT)
      throw new Error('The maximum file upload size is 1GB. Please compress or choose a different file')
    const renamedFile = renameFile(file)
    const url = await sharedAppStateStore.wrapAppLoading(uploadToS3(renamedFile), 'Uploading Media...')
    return { default: url.includes('fjvideo') ? decodeURIComponent(url) : url }
  }
}

function FJUploadAdapterPlugin(editor) {
  return (editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
    new FJCKEditorFileUploadAdapter(loader))
}

function MentionCustomization(editor) {
  // The upcast converter will convert view <a class="mention" href="" data-user-id="">
  // elements to the model 'mention' text attribute.
  editor.conversion.for('upcast').elementToAttribute({
    view: {
      name: 'a',
      key: 'data-mention',
      classes: 'mention',
      attributes: {
        href: true,
        'data-user-id': true,
      },
    },
    model: {
      key: 'mention',
      value: (viewItem) => {
        // The mention feature expects that the mention attribute value
        // in the model is a plain object with a set of additional attributes.
        // In order to create a proper object use the toMentionAttribute() helper method:
        const mentionAttribute = editor.plugins.get('Mention').toMentionAttribute(viewItem, {
          // Add any other properties that you need.
          link: viewItem.getAttribute('href'),
          userId: viewItem.getAttribute('data-user-id'),
        })

        return mentionAttribute
      },
    },
    converterPriority: 'high',
  })

  editor.conversion.for('upcast').elementToAttribute({
    view: {
      name: 'a',
      key: 'data-tags',
      classes: 'tags',
      attributes: {
        href: true,
      },
    },
    model: {
      key: 'mention',
      value: (viewItem) => {
        const mentionAttribute = editor.plugins.get('Mention').toMentionAttribute(viewItem, {
          id: viewItem.getAttribute('data-tags'),
          link: viewItem.getAttribute('href'),
        })

        return mentionAttribute
      },
    },
  })

  // Downcast the model 'mention' text attribute to a view <a> element.
  editor.conversion.for('downcast').attributeToElement({
    model: 'mention',
    view: (modelAttributeValue, { writer }) => {
      // Do not convert empty attributes (lack of value means no mention).
      if (!modelAttributeValue) {
        return
      }

      if (modelAttributeValue.id[0] === '@') {
        return writer.createAttributeElement(
          'a',
          {
            class: 'mention',
            'data-mention': modelAttributeValue.id,
            'data-user-id': modelAttributeValue.userId,
            href: modelAttributeValue.link,
            target: '_blank',
          },
          {
            // Make mention attribute to be wrapped by other attribute elements.
            priority: 20,
            // Prevent merging mentions together.
            id: modelAttributeValue.uid,
          }
        )
      } else {
        // '#' Tags
        return writer.createAttributeElement(
          'a',
          {
            class: 'tags',
            'data-tags': modelAttributeValue.id,
            href: modelAttributeValue.link,
          },
          {
            priority: 20,
            id: modelAttributeValue.uid,
          }
        )
      }
    },
    converterPriority: 'high',
  })
}

function VideoControls(editor: any) {
  const doc = editor.model.document
  doc.registerPostFixer((writer) => {
    for (const change of doc.differ.getChanges()) {
      const isInsertingMedia = change.type === 'insert' && change.name === 'media' && doc.getRoot().childCount === 1
      const isInsertingTable = change.type === 'insert' && change.name === 'table' && doc.getRoot().childCount === 1
      const isSettingSrc = change.type === 'attribute' && change.attributeKey === 'src'

      if (isInsertingMedia || isSettingSrc || isInsertingTable) {
        // Prevent adding space around if operation is happening inside table.
        if (change?.position?.parent?.name === 'tableCell' || change?.range?.start?.parent?.name === 'tableCell') return

        let position, element
        if (isInsertingMedia || isInsertingTable) {
          position = change.position
          element = change.position.root
        }
        if (isSettingSrc) {
          position = change.range.start
          element = change.range.root
        }

        // Add space before and after media
        writer.insert(writer.createElement('paragraph'), position, 'start')
        writer.insert(writer.createElement('paragraph'), element, position.offset + 2)

        // Put cursor after media
        // We are doing this because after uploading media, it's selected by default, if user click tag, uploaded media is replaced with it.
        // To avoid that, we should change selection
        editor.focus()
        editor.model.change((writer) => {
          writer.setSelection(writer.createPositionAt(element, position.offset + 3))
        })
      }
    }
  })

  editor.conversion.for('downcast').add((dispatcher: any) => {
    dispatcher.on('attribute:src:videoBlock', (evt, data, conversionApi) => {
      if (!conversionApi.consumable.consume(data.item, evt.name)) {
        return
      }

      const viewWriter = conversionApi.writer
      const figure = conversionApi.mapper.toViewElement(data.item)
      const video = figure.getChild(0)

      const src = data.attributeNewValue
      viewWriter.setAttribute('src', src || '', video)

      if (src.includes('https://')) {
        viewWriter.setAttribute('data-oembed-url', src, figure)
      }

      let id = ''
      if (src.includes('https://fj')) {
        id = src.replace('https://fj-file-uploads.s3.us-east-2.amazonaws.com/', '').split('.')?.[0]
      } else if (src.includes('https://cdn.flockjay.com/')) {
        id = src.replace('https://cdn.flockjay.com/', '').split('.')?.[0]
      } else if (src.includes(`${baseURL}/feed/files/`)) {
        /* eslint-disable */
        const regex =
          /^(?:https:\/\/(?:staging-)?api(?:-demo)?\.flockjay\.com|(?:http:\/\/)?localhost:8000)\/feed\/files\/(?:[a-zA-Z0-9\-]+)\/((?:[\w+]+(?:%[0-9A-Fa-f]{2}|\/))?fjvideo-[\w-]+).([\w-]+)/
        const match = src.match(regex)
        id = match?.[1]
      }
      viewWriter.setAttribute('id', id, video)
    })

    dispatcher.on(
      'insert:videoBlock',
      (evt: any, data: any, conversionApi: any) => {
        const viewWriter = conversionApi.writer
        const figure = conversionApi.mapper.toViewElement(data.item)
        const video = figure.getChild(0)
        viewWriter.setAttribute('preload', 'metadata', video)
        viewWriter.setAttribute('controls', true, video)
        viewWriter.setAttribute('controlslist', 'nodownload', video)
        viewWriter.setAttribute('style', 'max-width:100%', video)
      },
      { priority: 'low' }
    )
  })
}

interface FJCKEditorProps {
  name?: string
  data?: string
  /**
   Only use `errorMsg` when using CKEditor without formik.
   It won't work other wise
   */
  errorMsg?: string
  includeMentions?: boolean
  placeholder?: string
  hideToolbar?: boolean
  disabled?: boolean
  setEditor?: (editor: any) => void
  handleChange?: (data: string) => void
  showRecordVideoBtn?: boolean
  showRecordVideoTitle?: boolean
  autoStartRecord?: boolean
}

export default class FJCKEditor extends React.Component<FJCKEditorProps> {
  static defaultProps = {
    showRecordVideoTitle: true,
  }
  formikProps?: FormikProps<any>
  ckEditor: any

  componentDidCatch(error: Error) {
    sharedAppStateStore.handleError(error)
  }

  setFieldProps = (fieldProps: FieldProps) => {
    this.formikProps = fieldProps.form
  }

  editorReady = (editor: any) => {
    this.ckEditor = editor
    if (this.props.setEditor) this.props.setEditor(editor)
  }

  valueChanged = (_event: any, editor: any) => {
    // Set target=_blank for all links, without changing body value
    document.querySelectorAll('.ck-editor a').forEach((link) => {
      link.setAttribute('target', '_blank')
    })

    const el = document.createElement('div')
    el.innerHTML = editor.getData()
    el.querySelectorAll('div.raw-html-embed').forEach((rawEmbed: HTMLDivElement) => {
      if (containsJS(rawEmbed.innerHTML)) rawEmbed.innerHTML = sanitizeHtml(rawEmbed.innerHTML)
    })

    if (this.props.name) this.formikProps.setFieldValue(this.props.name, el.innerHTML)
    if (this.props.handleChange) this.props.handleChange(el.innerHTML)
  }

  getFeedUsers = async (queryText: string) => {
    let { data: users } = await User.list({
      search: queryText,
      is_active: true,
      access_role: 'standard,admin,manager,partner',
    })
    // Filter self from the list of fetched users
    users = users.filter((user: User) => user.id !== sharedDataStore.user.id)
    return users.map((user: User) => {
      return {
        id: `@${user.fullName}`,
        userId: user.id,
        name: user.fullName,
        link: Paths.getProfilePath(user.id),
      }
    })
  }

  getTagOptions = async (query: string = '') => {
    const tagsList: string[] = (await FeedTag.list({ search: query })).map((tag: FeedTag) => tag.tag)
    if (query && tagsList.indexOf(query) === -1) tagsList.unshift(query)
    return tagsList.map((tag) => {
      return {
        id: `#${tag}`,
        name: tag,
        link: Paths.getTagFeedPath(tag),
      }
    })
  }

  render() {
    const config = {
      extraPlugins: [FJUploadAdapterPlugin, MentionCustomization, VideoControls],
      placeholder: this.props.placeholder,
      link: { addTargetToExternalLinks: true },
      mention: this.props.includeMentions
        ? {
            feeds: [
              {
                marker: '@',
                feed: this.getFeedUsers,
              },
              {
                marker: '#',
                feed: this.getTagOptions,
              },
            ],
          }
        : undefined,
      htmlEmbed: {
        showPreviews: true,
        sanitizeHtml: (inputHtml: string) => {
          const outputHtml = containsJS(inputHtml) ? sanitizeHtml(inputHtml) : inputHtml
          return {
            html: outputHtml,
            hasChanged: inputHtml !== outputHtml,
          }
        },
      },
    }

    const showRecordVideoBtn =
      this.props.showRecordVideoBtn && !(sharedAppStateStore.isSafari() && sharedAppStateStore.isMobileDevice())

    // ckeditor sometimes has issues communicating with formik so we want the option to
    // use it independently
    return (
      <Wrapper showRecordVideoBtn={showRecordVideoBtn}>
        {this.props.name ? (
          <Field name={this.props.name}>
            {(fieldProps: FieldProps) => {
              this.setFieldProps(fieldProps)
              return (
                <CKEditor
                  editor={ClassicEditor}
                  disabled={this.props.disabled}
                  config={this.props.hideToolbar ? { ...config, toolbar: [] } : config}
                  data={fieldProps.meta.value}
                  onChange={this.valueChanged}
                  onBlur={this.valueChanged}
                  onReady={this.editorReady}
                />
              )
            }}
          </Field>
        ) : (
          <>
            <CKEditor
              editor={ClassicEditor}
              disabled={this.props.disabled}
              config={this.props.hideToolbar ? { ...config, toolbar: [] } : config}
              data={this.props.data}
              onChange={this.valueChanged}
              onBlur={this.valueChanged}
              onReady={this.editorReady}
            />
            {this.props.errorMsg ? <ErrorText>{this.props.errorMsg}</ErrorText> : null}
          </>
        )}
        {showRecordVideoBtn ? (
          <VideoRecordButton
            showRecordVideoTitle={this.props.showRecordVideoTitle}
            autoStartRecord={this.props.autoStartRecord}
            style={{ position: 'absolute', bottom: this.props.name ? '12px' : '36px', right: '12px' }}
            handleVideoRecordSuccess={(videoUrl) => this.ckEditor.execute('mediaEmbed', videoUrl)}
          />
        ) : null}
      </Wrapper>
    )
  }
}
