import { observable, makeObservable, computed, runInAction } from 'mobx'
import { RouteComponentProps } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import { ModalProps } from 'antd/lib/modal'
import { FeedPostFormProps } from 'src/components/Feed/FeedPostForm'
import { VideoRecorderProps } from 'src/components/Feed/VideoRecorder'
import { AddContentToContentContainerFormProps } from 'src/components/Feed/AddContentToContentContainerForm'
import { AddToQueueFormProps } from 'src/components/Feed/AddToQueueForm'
import { VideoModalFormProps } from 'src/components/Feed/VideoModalForm'
import { DeleteDialogProps } from 'src/components/Feed/DeleteDialog'
import { IAssetFormProps } from 'src/components/Feed/AssetForm'
import { camelToSentenceCase, parseStringBoolean } from 'src/utils/format'
import React from 'react'
import { BreadcrumbType } from 'src/components/Common/BreadcrumbBar'
import { IEditSharedContentModalProps, ISharedContentModalProps } from 'src/components/Feed/SharedContentModal'
import { PromptFormProps } from 'src/components/Feed/PromptForm'
import { SharedConfirmDialogProps } from 'src/components/Common/SharedConfirmDialog'
import { ISharedContentSessionsModalProps } from 'src/components/Feed/SharedContentSessions'
import { IShareCourseFormProps } from 'src/components/Feed/ShareCourseForm'
import { AssetSummaryProps } from 'src/components/Feed/AssetSummary'
import { ContentProgressDetailsProps } from 'src/components/Learn/ContentProgressDetails'
import { RubricFormProps } from 'src/components/CourseAuthoring/RubricForm'
import { RubricAuthoringFormProps } from 'src/components/CourseAuthoring/RubricAuthoringForm'
import { SharedLinkModalProps } from 'src/components/Feed/SharedLinkModal'
import { QueueModalProps } from 'src/components/Feed/QueueModal'
import { AddToContentModalProps } from 'src/components/Feed/AddToContentModal'
import { Paths } from 'src/constants/navigation'
import { TeamProgressModalProps } from 'src/components/Feed/TeamProgressModal'
import { ExternalAcademy } from 'src/models/ExternalAcademy'
import { ConfettiModalProps } from 'src/components/Common/ConfettiModal'
import { ISharedContentAssetViewModalProps } from 'src/components/Feed/SharedContentAssetViewModal'
import { IDealRoomAnalyticsModalProps } from 'src/components/Feed/DealRoomAnalyticsModal'
import { IEditDriveFileModalProps } from 'src/components/Feed/EditDriveFileModal'
import { generateID } from 'src/utils/token'
import { ICopyClipboardModalProps } from 'src/components/Feed/CopyClipboardModal'
import { PostAnalyticsModalProps } from 'src/components/Feed/PostAnalyticsModal'
import { ITemplateModalProps } from 'src/components/Feed/TemplateModal'
import { ITemplateAuthoringModalProps } from 'src/components/Feed/TemplateAuthoringModal'
import { LearningPathContentModalProps } from 'src/components/LearningPath/LearningPathContentModal'
import { LiveSessionAttendanceModalProps } from 'src/components/Learn/LiveSessionAttendanceModal'
import { getQueryParam } from 'src/utils/urlParams'
import { IAISummaryFeedbackModalProps } from 'src/components/Search/AIGeneratedSummary'
import { IBulkEditPopupProps } from 'src/components/Library/BulkEditPopup'
import { IBulkEditFormModalProps } from 'src/components/Library/BulkEditForm'
import { sharedDataStore } from 'src/store/DataStore'
import { setFrontendURL } from 'src/network/FlockjayProvider'
import { Asset } from 'src/models/Asset'
import { AdminModuleCompletionsFormProps } from 'src/components/Course/AdminModuleCompletionsForm'

class AppStateStore {
  @observable errMsg?: string | JSX.Element
  @observable isLoading: boolean = false
  @observable loadingMessage: string | JSX.Element
  @observable loadingProgress?: number
  @observable isPageFound: boolean = false
  @observable feedPostModalProps?: FeedPostFormProps = undefined
  @observable promptModalProps?: PromptFormProps = undefined
  @observable videoRecorderProps?: VideoRecorderProps = undefined
  @observable addContentToContainerModalProps?: AddContentToContentContainerFormProps = undefined
  @observable addToQueueModalProps?: AddToQueueFormProps = undefined
  @observable queueModalProps?: QueueModalProps
  @observable teamProgressModalProps?: TeamProgressModalProps
  @observable addContentModalProps?: AddToContentModalProps
  @observable deleteDialogProps?: DeleteDialogProps = undefined
  @observable sharedConfirmDialogProps?: SharedConfirmDialogProps = undefined
  @observable videoModalProps?: VideoModalFormProps = undefined
  @observable assetModalProps?: IAssetFormProps
  @observable assetSummaryModalProps?: AssetSummaryProps
  @observable postAnalyticsModalProps?: PostAnalyticsModalProps
  @observable contentProgressDetailsModalProps?: ContentProgressDetailsProps
  @observable confettiModalProps?: ConfettiModalProps
  @observable modalProps?: ModalProps & { children: JSX.Element }
  @observable sharedContentModalProps?: ISharedContentModalProps
  @observable editSharedContentModalProps?: IEditSharedContentModalProps
  @observable sharedContentSessionModalProps?: ISharedContentSessionsModalProps
  @observable sharedLinkModalProps?: SharedLinkModalProps
  @observable shareCourseFormModalProps?: IShareCourseFormProps
  @observable rubricFormModalProps?: RubricFormProps
  @observable rubricAuthoringFormModalProps?: RubricAuthoringFormProps
  @observable sharedContentAssetViewModalProps?: ISharedContentAssetViewModalProps
  @observable dealRoomAnalyticsModalProps?: IDealRoomAnalyticsModalProps
  @observable editDriveFileModalProps?: IEditDriveFileModalProps
  @observable copyClipboardModalProps?: ICopyClipboardModalProps
  @observable templateAuthoringModalProps?: ITemplateAuthoringModalProps
  @observable templateModalProps?: ITemplateModalProps
  @observable learningPathContentModalProps?: LearningPathContentModalProps
  @observable liveSessionAttendanceModalProps?: LiveSessionAttendanceModalProps
  @observable adminModuleCompletionsFormProps?: AdminModuleCompletionsFormProps
  @observable aiSummaryFeedbackModalProps?: IAISummaryFeedbackModalProps
  @observable bulkEditPopupProps?: IBulkEditPopupProps
  @observable bulkEditFormModalProps?: IBulkEditFormModalProps
  @observable googleMapApiLoaded: boolean = false
  createContentContainerSeed: any = undefined
  @observable showNotificationDrawer = false
  @observable showTaskDrawer = false
  @observable isMobile = false
  @observable hideVideoRecordModal = false
  initialConfigsLoaded = false
  sessionId = generateID()
  isInChromeExtension = false
  isInCanvas = false

  @observable private _externalAcademy?: ExternalAcademy
  @observable private navProps?: RouteComponentProps

  @computed get routes(): BreadcrumbType[] {
    if (this.navProps?.location?.state?.['routes']) {
      return JSON.parse(this.navProps.location.state?.['routes'])
    }
    return []
  }

  @computed get externalAcademy() {
    return this._externalAcademy
  }

  @computed get isNavigatingLearningPath() {
    return this.routes.some((route) => route.contentType === 'learningpath')
  }

  @computed get learningPathRouteHistory() {
    return this.routes.filter((route) => route.contentType === 'learningpath')
  }

  constructor() {
    makeObservable(this)
  }

  setNavigation(props: RouteComponentProps) {
    this.navProps = props
  }

  setRenderContext = () => {
    this.isInChromeExtension = getQueryParam('extension') === 'true'
    this.isInCanvas = parseStringBoolean(getQueryParam('canvas'))
  }

  resetBreadcrumbs() {
    this.updateRoutesState([])
  }

  updateRoutesState(routes: BreadcrumbType[]) {
    this.navProps?.history.replace(this.navProps.location.pathname + this.navProps.location.search, {
      routes: JSON.stringify(routes),
    })
  }

  removeQueryParam = (key: string) => {
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.has(key)) {
      queryParams.delete(key)
      this.navProps?.history.replace({ search: queryParams.toString() })
    }
  }

  resetRoutesToLastLearningPath(selectedLearningPathContentId: string) {
    const routes = this.getRoutesWithLastLearningPathContentUpdated(selectedLearningPathContentId)
    this.updateRoutesState(routes)
  }

  getRoutesWithLastLearningPathContentUpdated(selectedLearningPathContentId: string) {
    let routes = [...this.routes]
    // @ts-ignore
    const lastLearningPathRouteIndex = this.routes.findLastIndex((route) => route.contentType === 'learningpath')
    routes = routes.slice(0, lastLearningPathRouteIndex + 1)

    routes[lastLearningPathRouteIndex] = {
      ...routes[lastLearningPathRouteIndex],
      selectedLearningPathContentId,
    }
    return routes
  }

  navigateBack() {
    this.navProps.history.goBack()
  }

  navigate(
    target: string,
    replace: boolean = false,
    breadcrumbConfig?: {
      breadcrumbName?: string
      fromBreadcrumb?: boolean
      path?: string
      retain?: boolean
      contentType?: 'hub' | 'learningpath' | 'course'
      selectedLearningPathContentId?: string
    }
  ) {
    let routes = [...this.routes]
    const { breadcrumbName, contentType, selectedLearningPathContentId, fromBreadcrumb, retain, path } =
      breadcrumbConfig || {}
    if (fromBreadcrumb) {
      const newRoutes = []
      for (const route of this.routes) {
        if (route.path === target) {
          break
        } else {
          newRoutes.push(route)
        }
      }
      routes = newRoutes
    } else if (breadcrumbName) {
      const breadcrumb = routes.find((route) => route.path === path)
      if (!breadcrumb) {
        routes.push({
          selectedLearningPathContentId,
          breadcrumbName,
          path: path ?? this.navProps.location.pathname + this.navProps.location.search,
          contentType,
        })
      }
    } else if (retain && selectedLearningPathContentId) {
      routes = this.getRoutesWithLastLearningPathContentUpdated(selectedLearningPathContentId)
    } else if (!retain) {
      routes = []
    }

    if (this.navProps && replace) this.navProps.history.replace(target, { routes: JSON.stringify(routes) })
    else if (this.navProps) this.navProps.history.push(target, { routes: JSON.stringify(routes) })
  }

  handleError(err: any, fallbackMsg?: string, alert: boolean = true, message?: string | JSX.Element) {
    if (err?.code === 'ERR_CANCELED') return
    if (err.response) {
      Sentry.withScope((scope) => {
        scope.setExtra('status', err.response?.status)
        scope.setExtra('requestUrl', err.response?.config?.url)
        scope.setExtra('requestData', err.response?.config?.data)
        scope.setExtra('responseData', err.response?.data)
        Sentry.captureException(err)
      })
    } else {
      Sentry.captureException(err)
    }

    if (!alert || err.response?.status === 404) return

    let msg: string | JSX.Element = 'An error occured'

    let serverMsg: string | JSX.Element = ''
    if (err.response?.status === 500) {
      serverMsg = (
        <>
          <p>
            We have been notified and are working on a fix. In the mean time try refreshing the page. <br />
            <br />
            If the problem persists you can email us at <a href="mailto:support@flockjay.com">support@flockjay.com</a>
          </p>
        </>
      )
    } else if (err.response?.data) {
      if (Array.isArray(err.response.data)) {
        const values = Object.values(err.response.data).flat(1)
        if (values[0] && typeof values[0] === 'string') serverMsg = values[0]
      } else {
        serverMsg = Object.entries(err.response.data)
          .map(([key, value]) => {
            const errValue = Array.isArray(value) ? value[0] : value
            return errValue && typeof errValue === 'string' ? `${camelToSentenceCase(key)}: ${errValue}` : ''
          })
          .join('\n')
      }
    }

    if (message) msg = message
    else if (serverMsg) msg = serverMsg
    else if (err.message) msg = err.message
    else if (fallbackMsg) msg = fallbackMsg

    if (typeof msg === 'string' && /Loading chunk \w+ failed/.test(msg)) {
      msg = 'A newer version of Flockjay is available! Please refresh the page to update the application.'
    } else if (typeof msg === 'string' && msg.startsWith('Detail: ')) {
      msg = msg.slice(8)
    }

    this.errMsg = msg
  }

  clearError = () => {
    this.errMsg = undefined
  }

  async wrapAppLoading(promise: Promise<any>, message?: string | JSX.Element) {
    runInAction(() => {
      this.loadingMessage = message
      this.isLoading = true
    })
    try {
      return await promise
    } catch (err) {
      throw err
    } finally {
      runInAction(() => {
        this.isLoading = false
        this.loadingMessage = undefined
      })
    }
  }

  updateAppLoadingProgress = (progress?: number) => {
    if (!progress) {
      this.loadingProgress = undefined
      return
    }
    this.loadingProgress = Math.round(progress * 100)
  }

  setPageFound() {
    this.isPageFound = true
  }

  mediaQueryChangerListener = (e: MediaQueryListEvent) => (this.isMobile = e.matches)

  setFeedPostIsUpdated = (data: string) => {
    if (this.feedPostModalProps && data) {
      this.feedPostModalProps.isFeedPostUpdated = true
    }
  }

  setAssetIsUpdated = (data: string) => {
    if (this.assetModalProps && data) {
      this.assetModalProps.isAssetUpdated = true
    }
  }

  setPromptIsUpdated = (data: string) => {
    if (this.promptModalProps && data) {
      this.promptModalProps.isPromptUpdated = true
    }
  }

  isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }

  isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  isEmbed = () => window.location.pathname.startsWith(Paths.embed)

  hasPDFViewerEnabled = () => {
    try {
      // Modern browsers
      if (navigator['pdfViewerEnabled'] !== undefined) return navigator['pdfViewerEnabled']

      // Older browsers and Safari don't have `pdfViewerEnabled` property so have to check
      // deprecated mimeTypes array
      return (
        navigator.mimeTypes &&
        navigator.mimeTypes['application/pdf'] &&
        navigator.mimeTypes['application/pdf'].enabledPlugin
      )
    } catch {
      return false
    }
  }

  getExternalAcademy = async () => {
    try {
      const externalAcademy = await ExternalAcademy.get(undefined, { urlPath: 'origin' })
      this.setAcademy(externalAcademy)
    } catch (err) {
      // do nothing
    }
  }

  setAcademy = (academy: ExternalAcademy) => {
    this._externalAcademy = academy
    // set custom academy page styles if necessary
    const style = document.getElementById('custom-academy-styles') as HTMLStyleElement
    style.replaceChildren(document.createTextNode(academy.customStyle || ''))

    const isPublicUser = sharedDataStore.user.isFaasPublic()
    const isAnonymous = sharedDataStore.user.isAnonymous()

    if (!isPublicUser && !isAnonymous) return

    if (academy.customDomain) setFrontendURL(academy.customDomain)
    if (academy.data.globalStyleClass) document.body.classList.add(academy.data.globalStyleClass)
  }

  openAssetModalFromQueryParams = async () => {
    const assetId = getQueryParam('editAssetId')
    const openDrive = parseStringBoolean(getQueryParam('openDrive'))
    if (!assetId) return

    try {
      let asset: Asset | undefined = undefined
      if (assetId !== 'new') asset = await sharedAppStateStore.wrapAppLoading(Asset.get(assetId))

      const closeAssetModal = () => (sharedAppStateStore.assetModalProps = undefined)

      sharedAppStateStore.assetModalProps = {
        asset,
        initialTab: openDrive ? 'upload' : undefined,
        openDriveModal: openDrive,
        onSuccess: closeAssetModal,
        onCancel: closeAssetModal,
      }
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined, false)
    }
  }
}

const sharedAppStateStore = new AppStateStore()
export { sharedAppStateStore }
