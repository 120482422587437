import styled from 'styled-components'
import { Colors } from 'src/constants/colors'
import React from 'react'
import { FjText } from 'src/components/Common'
import { camelToSentenceCase } from 'src/utils/format'

export const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${Colors.sharkOpacity12};
  border-radius: 8px;
  overflow: hidden;
  background: ${Colors.white};
  & > *:not(:last-child) .category-item,
  & .category-item:not(:last-child) {
    border-bottom: 1px solid ${Colors.sharkOpacity10};
  }
`

const CategoryContainer = styled.div<{ isSubTab: boolean }>`
  position: relative;
  background: ${Colors.white};
  padding: 12px 16px 12px 24px;
  cursor: pointer;
  transition: 0.2s;
  padding-left: ${(props) => (props.isSubTab ? 'calc(7% + 24px)' : '24px')};
  text-align: left;

  :hover {
    background: ${Colors.hawkesBlue};
  }
`

const CategoryHighlight = styled.div<{ isSubTab: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 7px;
  height: 100%;
  background-color: ${Colors.texasRose};
  margin-left: ${(props) => (props.isSubTab ? '20px' : '0')};
`

export const CategoryItem: React.FC<{
  category: string | React.ReactNode
  active?: boolean
  onClick?: () => void
  isSubTab?: boolean
  containsSubCategory?: boolean
}> = ({ category, active, onClick, isSubTab }) => {
  return (
    <CategoryContainer onClick={onClick} isSubTab={isSubTab} className="category-item">
      {active && <CategoryHighlight isSubTab={isSubTab} />}
      <FjText fontSize="14px" color={Colors.chambray} fontWeight="bold" textTransform="capitalize" textAlign="left">
        {typeof category === 'string' ? camelToSentenceCase(category) : category}
      </FjText>
    </CategoryContainer>
  )
}
