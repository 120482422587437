import { DataObject } from 'src/models/DataObject'
import { Question } from 'src/models/Question'
import moment from 'moment-timezone'
import { observable, makeObservable } from 'mobx'
import { formatDateTime } from 'src/utils/format'
import { sharedDataStore } from 'src/store/DataStore'
import { Author } from 'src/models/Author'
import { FlockjayProvider } from 'src/network/FlockjayProvider'
import Route from 'src/network/Route'
import MethodTypes from 'src/models/enums/MethodTypes'

export class Assignment extends DataObject {
  static OVERRIDE_MAPPINGS = {
    questions: (data) => (data.questions ? Question.fromData(data.questions) : []),
    graderIds: (data) => (data.graderIds ? data.graderIds : []),
    graders: (data) => (data.graders ? Author.fromData(data.graders) : []),
  }
  static apiEndpoint = '/api/assignments/'
  static shouldUseCache: boolean = true

  id: string
  @observable title: string
  maxAttempts?: number
  passScore?: number
  graderId: string
  graderIds: string[] = []
  graders: Author[] = []
  assignmentGradingType: 'admin_and_manager' | 'users_manager' | 'assigned_user'
  gradingDueDate: moment.Moment = moment()
  questions: Question[] = []
  description?: string
  createdAt: moment.Moment = moment()
  lastUpdated: moment.Moment = moment()
  submoduleId: string

  constructor() {
    super()
    makeObservable(this)
  }

  gradingDueDateStr = (displayFormat?: string) => {
    return this.gradingDueDate ? formatDateTime(this.gradingDueDate, displayFormat) : ''
  }

  isPassedGradingDueDate = () => {
    return this.gradingDueDate && moment().diff(this.gradingDueDate) >= 0
  }

  totalPoints = () => {
    if (!this.questions) return 0
    return this.questions.reduce(
      (totalPoints, question) =>
        question.gradeType === 'binary'
          ? totalPoints + 1
          : question.gradeType === 'rubric'
          ? totalPoints + question.rubric.getRubricPoints()
          : totalPoints + 5,
      0
    )
  }

  scoredPoints = () => {
    if (!this.questions) return 0
    return this.questions.reduce((scoredPoints, question) => {
      if (question.studentAnswer && question.studentAnswer.score) {
        return scoredPoints + question.studentAnswer.score
      }
      return scoredPoints
    }, 0)
  }
  /**
   *
   * @param managerId this param is manager id of user that submitted assignment.
   * @returns
   */

  hasPermissionToGrade = (managerId: string) => {
    return (
      sharedDataStore.user.isFaasAdminOrManager() ||
      managerId === sharedDataStore.user.id ||
      this.graderIds.includes(sharedDataStore.user.id)
    )
  }

  getPassScorePercent = () => {
    if (!this.passScore) return null
    return Math.round(this.passScore * 100)
  }

  getAnalytics = async () => {
    const { path } = Assignment.formatActionEndpoint(this.id, undefined, `analytics`)
    return await FlockjayProvider(new Route(MethodTypes.GET, path))
  }
}
