import { Popover } from 'antd'
import React from 'react'
import { ContainerDiv, FjText } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { CoachingMoment } from 'src/models/CoachingMoment'
import { Hub } from 'src/models/Hub'
import { Page } from 'src/models/Page'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import {
  AppearsInType,
  ContentTypeIcon,
  LearningContent,
  getFeedContentData,
  getFeedContentTargetLink,
} from 'src/utils/content'

const AppearsInItem = ({ item }: { item: AppearsInType }) => {
  const targetLink = getFeedContentTargetLink(getFeedContentData({ id: item.id }, item.contentType))

  const handleTitleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    sharedAppStateStore.navigate(targetLink)
  }

  return (
    <ContainerDiv display="flex" alignItems="center" gap={4} whiteSpace="nowrap" cursor="pointer">
      <ContentTypeIcon size={14} color={Colors.cornflowerBlue} contentType={item.contentType} />
      <FjText fontSize="small" color={Colors.cornflowerBlue} onClick={handleTitleClick}>
        {item.title}
      </FjText>
    </ContainerDiv>
  )
}

type AppearsInProps = { obj: LearningContent | Hub | Page | CoachingMoment }

export const AppearsIn = ({ obj }: AppearsInProps) => {
  const { appearsIn } = obj
  return (
    <ContainerDiv display="flex" alignItems="center" minHeight={18} flexWrap="wrap" width="100%">
      {appearsIn.length > 0 ? (
        <ContainerDiv display="flex" alignItems="center" gap={8}>
          <FjText fontSize="small" color={Colors.tapa} style={{ whiteSpace: 'nowrap' }}>
            Appears in
          </FjText>
          <AppearsInItem item={appearsIn[0]} />
        </ContainerDiv>
      ) : null}

      {appearsIn.length > 1 ? (
        <Popover
          trigger={['hover', 'click']}
          placement="top"
          showArrow
          content={
            <ContainerDiv display="flex" flexDirection="column" alignItems="flex-start" gap={4}>
              {appearsIn.map((item, index) => {
                if (index === 0) return null
                return <AppearsInItem key={index} item={item} />
              })}
            </ContainerDiv>
          }
        >
          <FjText fontSize="small" color={Colors.cornflowerBlue}>
            {`+ ${appearsIn.length - 1} more`}
          </FjText>
        </Popover>
      ) : null}
    </ContainerDiv>
  )
}
