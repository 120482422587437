import React from 'react'
import { FjCard, DeleteButton, EditButton, FjText } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { Space } from 'antd'
import { Rubric } from 'src/models/Rubric'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { AuthorDisplay } from 'src/components/Common/AuthorDisplay'
import { pluralize } from 'src/utils/format'

type RubricCardProps = {
  rubric: Rubric
  onRubricDeleteSuccess?: () => void
  onRubricEditSuccess?: () => void
}

const RubricCard = ({ rubric, onRubricDeleteSuccess, onRubricEditSuccess }: RubricCardProps) => {
  const rubricPoints = rubric.getRubricPoints()

  const handleRubricEdit = () => {
    const onEditRubric = () => {
      sharedAppStateStore.rubricAuthoringFormModalProps = undefined
      onRubricEditSuccess?.()
    }
    sharedAppStateStore.rubricAuthoringFormModalProps = { rubric, onSaveSuccess: onEditRubric }
  }

  const handleRubricDelete = () => {
    const onDeleteRubric = async () => {
      try {
        await sharedAppStateStore.wrapAppLoading(rubric.delete())
        onRubricDeleteSuccess?.()
      } catch (err) {
        sharedAppStateStore.handleError(err)
      }
    }
    sharedAppStateStore.deleteDialogProps = {
      onConfirm: onDeleteRubric,
      obj: rubric,
    }
  }

  return (
    <FjCard display="flex" textAlign="left" justifyContent="space-between">
      <Space size={16}>
        <AuthorDisplay author={rubric.author} showName={false} avatarSize={40} />
        <Space direction="vertical">
          <FjText fontWeight="semi-bold" color={Colors.cornflowerBlue}>
            {rubric.title}
          </FjText>
          <FjText textAlign="left">
            {rubricPoints} {pluralize('pt', rubricPoints)}
          </FjText>
        </Space>
      </Space>
      <Space size={16}>
        <EditButton color={Colors.cornflowerBlue} obj={rubric} onClick={handleRubricEdit} />
        <DeleteButton fontSize={24} onClick={handleRubricDelete} />
      </Space>
    </FjCard>
  )
}

export default RubricCard
