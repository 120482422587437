import { Tooltip } from 'antd'
import React from 'react'
import { ContainerDiv, FjAvatar, FjText, InternalUseTag } from 'src/components/Common'
import { DateInfoPopover } from 'src/components/Feed/DateInfoPopover'
import { Colors } from 'src/constants/colors'
import { Paths } from 'src/constants/navigation'
import { Asset } from 'src/models/Asset'
import { CoachingMoment } from 'src/models/CoachingMoment'
import { FeedTask } from 'src/models/FeedTask'
import { Call } from 'src/models/Call'
import { Hub } from 'src/models/Hub'
import { Page } from 'src/models/Page'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { sharedDataStore, ViewMode } from 'src/store/DataStore'
import { LearningContent, getFeedContentTitle } from 'src/utils/content'

export const AuthorAndTitle: React.FC<{
  targetObj: LearningContent | Hub | Page | FeedTask
  textColor?: string
  viewMode?: ViewMode
}> = ({ targetObj, textColor = Colors.midnightBlue, viewMode = 'card' }) => {
  const isOpportunity = targetObj instanceof SfOpportunity
  const isCall = targetObj instanceof Call
  const isAsset = targetObj instanceof Asset
  const isTask = targetObj instanceof FeedTask
  const isCoachingMoment = targetObj instanceof CoachingMoment

  const title = getFeedContentTitle(targetObj)

  const handleClickAuthor = (e: React.MouseEvent) => {
    e.preventDefault()
    if (isOpportunity || sharedDataStore.user.isFaasPublicOrPartner()) return
    sharedAppStateStore.navigate(Paths.getProfilePath(targetObj.author.authorID))
  }

  const showUpdated =
    (isAsset && !targetObj.hasGoogleDriveMetadata()) || (viewMode === 'list' && !isTask && !isCall && !isCoachingMoment)
  const isInternalAsset = isAsset && targetObj.isInternal

  return (
    <ContainerDiv
      display="flex"
      width="100%"
      justifyContent="space-between"
      flexDirection={viewMode === 'list' ? 'row' : 'column'}
      alignItems={viewMode === 'list' ? 'center' : undefined}
      height={viewMode === 'list' ? undefined : '46px'}
      overflow="hidden"
    >
      <ContainerDiv display="flex" alignItems="center">
        {!isOpportunity && targetObj?.author ? (
          <ContainerDiv height="100%" textAlign="left" cursor="pointer" onClick={handleClickAuthor}>
            <FjAvatar author={targetObj?.author} style={{ marginRight: '7px' }} />
          </ContainerDiv>
        ) : null}
        <ContainerDiv display="flex" height="100%" flexDirection="column">
          <ContainerDiv display="flex" flexDirection="row" gap="12px" alignItems="center">
            <Tooltip title={isInternalAsset ? `${title} (Internal Use Only)` : title}>
              <FjText
                textAlign="left"
                fontWeight="bold"
                wordBreak="break-word"
                color={textColor}
                fontSize={viewMode === 'list' ? 'clamp(0.875rem, 0.4063rem + 1.5vw, 1.25rem)' : undefined}
                lineHeight={viewMode === 'list' ? '30px' : '22px'}
                rows={viewMode === 'list' || (viewMode === 'card' && showUpdated) ? 1 : 2}
                style={{ padding: '1px 0' }}
              >
                {title}
              </FjText>
            </Tooltip>
            {viewMode === 'list' && isInternalAsset ? <InternalUseTag /> : null}
          </ContainerDiv>
          {viewMode === 'card' && (
            <ContainerDiv textAlign="left">
              {isInternalAsset && (
                <FjText fontSize="small" color={Colors.cottonSeed}>
                  Internal Use Only{' '}
                </FjText>
              )}
              {showUpdated && (
                <>
                  {isInternalAsset && (
                    <FjText fontSize="small" color={Colors.cottonSeed}>
                      |{' '}
                    </FjText>
                  )}
                  <DateInfoPopover obj={targetObj} viewMode="card" />
                </>
              )}
            </ContainerDiv>
          )}
        </ContainerDiv>
      </ContainerDiv>
      {viewMode === 'list' && showUpdated ? <DateInfoPopover obj={targetObj} viewMode="list" /> : null}
    </ContainerDiv>
  )
}
