import React, { Component, Suspense } from 'react'
import { Formik } from 'formik'
import { Form, Switch } from 'formik-antd'
import { AutomationRule } from 'src/models/AutomationRule'
import {
  ContainerDiv,
  FjQueryBuilderField,
  FjFormItem,
  FjInput,
  FormActionButtons,
  FormHeaderText,
  GroupSelector,
  FjContentField,
  ContentDataOptionType,
  DeleteButton,
  DefaultButton,
  FjText,
  Loader,
  FjCard,
  FjContentFieldTitleContainer,
  DEFAULT_DUE_DATE_OFFSET,
} from 'src/components/Common'
import { Utils as QbUtils, AntdConfig, ImmutableTree, Config } from '@react-awesome-query-builder/antd'
import '@react-awesome-query-builder/antd/css/compact_styles.css'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { isRequired } from 'src/utils/validation'
import { LearningContentType, fetchContent, getFeedContentTitle, getFieldsParam } from 'src/utils/content'
import { observer } from 'mobx-react'
import { observable, makeObservable } from 'mobx'
import { Colors } from 'src/constants/colors'
import { Divider } from 'antd'
import { sharedDataStore } from 'src/store/DataStore'
import { Plus } from 'react-feather'
import { User } from 'src/models/User'
import { camelToSentenceCase, camelize } from 'src/utils/format'

const InitialConfig = AntdConfig

const DIVIDER_STYLES: React.CSSProperties = { margin: '5px 2px', height: '4px' }

export interface IAutomationRuleFormProps {
  onSuccess: (rule: AutomationRule) => Promise<void>
  onCancel: () => void
  rule?: AutomationRule
}

@observer
export class AutomationRuleForm extends Component<IAutomationRuleFormProps> {
  queryBuilderConfig: Config
  @observable isLoading = false
  @observable outcomeContents: {
    contentId: string
    contentType: LearningContentType
    contentTitle: string
    dueDateOffset?: number
  }[]
  contentOptions = {}

  constructor(props: IAutomationRuleFormProps) {
    super(props)
    makeObservable(this)
    this.queryBuilderConfig = this.createQueryBuilderConfig()
    if (props.rule && props.rule.outcome.content) {
      this.outcomeContents = props.rule.outcome.content
    } else {
      this.outcomeContents = [
        {
          contentId: undefined,
          contentType: undefined,
          contentTitle: undefined,
          dueDateOffset: DEFAULT_DUE_DATE_OFFSET,
        },
      ]
    }
  }

  createQueryBuilderConfig = () => {
    const config = {
      ...InitialConfig,
      fields: {
        users: {
          type: '!struct',
          label: 'User',
          subfields: {
            cost_center: {
              type: 'text',
              label: 'Cost Center',
              operators: ['equal'],
            },
            department: {
              type: 'text',
              label: 'Department',
              operators: ['equal'],
            },
            division: {
              type: 'text',
              label: 'Division',
              operators: ['equal'],
            },
            groups: {
              type: 'multiselect',
              label: 'Group',
              fieldSettings: {
                listValues: sharedDataStore.user.groups.map((group) => ({ value: group.id, title: group.name })),
              },
              operators: ['multiselect_equals'],
            },
            job_position: {
              type: 'text',
              label: 'Job Title',
              operators: ['equal'],
            },
            location_state: {
              type: 'text',
              label: 'Location (State)',
              operators: ['equal'],
            },
            location_country: {
              type: 'text',
              label: 'Location (Country)',
              operators: ['equal'],
            },
            manager: {
              type: 'select',
              label: 'Manager',
              operators: ['select_equals'],
              fieldSettings: {
                useAsyncSearch: true,
                asyncFetch: async (search: string) => {
                  const { data } = await User.list({ search, is_active: true, has_reports: true })
                  return {
                    values: data.map((user) => ({ value: user.id, title: user.fullName })),
                    hasMore: false,
                  }
                },
                allowCustomValues: false,
              },
            },
            organization: {
              type: 'text',
              label: 'Organization',
              operators: ['equal'],
            },
            access_role: {
              type: 'select',
              label: 'Role',
              fieldSettings: {
                listValues: [
                  {
                    title: 'Admin',
                    value: 'admin',
                  },
                  {
                    title: 'Manager',
                    value: 'manager',
                  },
                  {
                    title: 'Standard',
                    value: '',
                  },
                ],
                allowCustomValues: false,
              },
              operators: ['select_equals'],
            },
            start_date: {
              type: 'date',
              label: 'Start Date',
              operators: ['equal', 'greater', 'greater_or_equal', 'less', 'less_or_equal'],
              fieldSettings: {
                dateFormat: 'YYYY-MM-DD',
                enableTime: false,
                inputProps: {
                  placeholder: 'Select date',
                },
              },
            },
          },
        },
        learning_content: {
          type: '!struct',
          label: 'Content',
          subfields: {
            course: this.buildContentField('Course', 'course'),
            learning_path: this.buildContentField('Learning Path', 'learningpath'),
            playlist: this.buildContentField('Collection', 'playlist'),
            prompt: this.buildContentField('Prompt', 'prompt'),
          },
        },
        // learning_path_completions: {
        //   type: '!struct',
        //   label: 'Content Completion',
        //   subfields: {
        //     completed_at: {
        //       type: 'boolean',
        //       label: 'Completed',
        //       operators: ['equal'],
        //       defaultValue: true,
        //     },
        //   },
        // },
      },
      settings: { ...InitialConfig.settings, maxNesting: 2, showNot: false },
    }

    if (sharedDataStore.config.scimFields.length) {
      sharedDataStore.config.scimFields.forEach((fieldName) => {
        config['fields']['users']['subfields'][`data__${fieldName}`] = {
          type: 'text',
          label: camelToSentenceCase(camelize(fieldName, '_')),
          operators: ['equal'],
        }
      })
    }

    return config
  }

  buildContentField = (label: string, contentType: LearningContentType) => {
    return {
      type: 'select',
      label: `${label} Completed`,
      fieldSettings: {
        useAsyncSearch: true,
        asyncFetch: async (search: string) => {
          const queryParams = {
            page: 1,
            search,
            sort_by: 'recent',
            fields: getFieldsParam(contentType),
          }
          if (contentType === 'feedpost') queryParams['exclude_linked'] = true
          const { data } = await fetchContent(contentType, queryParams)
          return {
            values: data.map((obj) => ({ value: obj.id, title: getFeedContentTitle(obj) })),
            hasMore: false,
          }
        },
        allowCustomValues: false,
      },
      operators: ['select_equals'],
    }
  }

  getInitialValues = () => ({
    title: this.props.rule?.title || undefined,
    queryTree: this.props.rule ? QbUtils.loadTree(this.props.rule.queryTree) : undefined,
    groupIds: this.props.rule?.outcome?.groupIds || [],
    isActive: this.props.rule ? this.props.rule.isActive : true,
  })

  handleSubmit = async (data: any) => {
    try {
      const { queryTree, groupIds, ...formData } = data
      const rule: AutomationRule = this.props.rule
        ? AutomationRule.fromData({ ...this.props.rule })
        : new AutomationRule()

      const outcome = { groupIds, content: [...this.outcomeContents] }

      const submissionData = {
        ...formData,
        outcome,
        preCondition: QbUtils.jsonLogicFormat(queryTree, this.queryBuilderConfig).logic,
        queryTree: QbUtils.getTree(queryTree),
      }

      await rule.save(submissionData, true, true)
      this.props.onSuccess(rule)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  validateQueryTree = (immutableTree: ImmutableTree) => {
    const { logic } = QbUtils.jsonLogicFormat(immutableTree, this.queryBuilderConfig)
    if (!logic) return 'This field is required'
  }

  validateTargetContents = () => {
    if (this.outcomeContents.length === 0) return 'This field is required'
    for (const content of this.outcomeContents) {
      for (const key of Object.keys(content)) {
        if (!content[key]) return 'Please fill in all fields'
      }
    }
  }

  addTargetContent = () => {
    this.outcomeContents = [
      ...this.outcomeContents,
      { contentId: undefined, contentType: undefined, contentTitle: undefined, dueDateOffset: DEFAULT_DUE_DATE_OFFSET },
    ]
  }

  handleTargetContentChanged = (index: number, option: ContentDataOptionType) => {
    const newOutcomes = [...this.outcomeContents]
    newOutcomes[index] = {
      contentId: option.key,
      contentType: option.contentType,
      contentTitle: option.label,
      dueDateOffset: option.dueDateOffset,
    }
    this.outcomeContents = newOutcomes
  }

  deleteTargetContent = (index: number) => (this.outcomeContents = this.outcomeContents.filter((_, i) => i !== index))

  render() {
    const { rule } = this.props
    return (
      <ContainerDiv textAlign="left" id="queryForm">
        <FormHeaderText heading={rule ? `Edit Rule: ${rule.title}` : 'Create Rule'} />
        {this.isLoading ? (
          <Loader />
        ) : (
          <Formik initialValues={this.getInitialValues()} onSubmit={this.handleSubmit}>
            <Form>
              <ContainerDiv display="flex" alignItems="center" gap="20px">
                <FjFormItem fieldtitle="Title*" name="title" validate={isRequired} style={{ width: '80%' }}>
                  <FjInput name="title" placeholder="Title" />
                </FjFormItem>
                <FjFormItem fieldtitle="Active" name="isActive" style={{ margin: '0 auto' }}>
                  <Switch name="isActive" />
                </FjFormItem>
              </ContainerDiv>
              <FjText
                display="block"
                textAlign="left"
                color={Colors.tapa}
                marginBottom
                fontWeight="semi-bold"
                fontSize="16px"
                marginLeft="5px"
              >
                If...
              </FjText>
              <FjFormItem name="queryTree" validate={this.validateQueryTree}>
                <FjQueryBuilderField name="queryTree" config={this.queryBuilderConfig} />
              </FjFormItem>
              <FjText
                display="block"
                textAlign="left"
                color={Colors.tapa}
                marginBottom
                fontWeight="semi-bold"
                fontSize="16px"
                marginLeft="5px"
              >
                Then:
              </FjText>
              <FjCard padding="12px">
                <GroupSelector name="groupIds" fieldTitle="Add users to the following group(s)" />
                <ContainerDiv textAlign="left">
                  <Divider>
                    <FjText color={Colors.tapa} textTransform="uppercase">
                      And / Or
                    </FjText>
                  </Divider>
                  <FjContentFieldTitleContainer>
                    <FjText textAlign="left" color={Colors.tapa}>
                      Assign the following content:
                    </FjText>
                    <FjText textAlign="left" color={Colors.tapa}>
                      Due
                    </FjText>
                  </FjContentFieldTitleContainer>

                  <FjCard textAlign="left" marginBottom padding="10px" marginTop="10px">
                    {this.outcomeContents.map((c, i) => (
                      <ContainerDiv key={`target-content-${i}`}>
                        <ContainerDiv
                          paddingRight={sharedAppStateStore.isMobile ? '40px' : undefined}
                          position="relative"
                        >
                          <ContainerDiv position="absolute" right={0} top={0}>
                            <DeleteButton onClick={() => this.deleteTargetContent(i)} fontSize={14} type="filled" />
                          </ContainerDiv>
                          <FjContentField
                            index={i}
                            value={{
                              key: c.contentId,
                              value: c.contentId,
                              label: c.contentTitle,
                              contentType: c.contentType,
                              dueDateOffset: c.dueDateOffset,
                            }}
                            onChange={this.handleTargetContentChanged}
                          />
                        </ContainerDiv>
                        <Divider style={DIVIDER_STYLES} />
                      </ContainerDiv>
                    ))}
                    <ContainerDiv textAlign="right" margin="auto">
                      <DefaultButton
                        image={<Plus size={16} color={Colors.white} />}
                        buttonType="primary"
                        clicked={this.addTargetContent}
                      />
                    </ContainerDiv>
                  </FjCard>
                </ContainerDiv>
              </FjCard>

              <FormActionButtons submitButtonLabel="Save" />
            </Form>
          </Formik>
        )}
      </ContainerDiv>
    )
  }
}

export const LazyAutomationRuleForm = (props: IAutomationRuleFormProps) => {
  return (
    <Suspense fallback={<Loader />}>
      <AutomationRuleForm {...props} />
    </Suspense>
  )
}
