import React, { ComponentProps } from 'react'
import styled from 'styled-components'
import { Tooltip } from 'antd'
import { getPaddingObj, PaddingProps, getMarginObj, MarginProps } from 'src/components/Common/Styles'
import { FjText, Icon, TextCustomProps, TextSizeOption } from 'src/components/Common'
import { Colors } from 'src/constants/colors'

const SimpleInput = styled.input`
  text-align: left;
  color: ${Colors.outerSpace};
  background-color: ${Colors.white};
  border: 1px solid ${Colors.darkIron};
  box-sizing: border-box;
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  font-size: 14px;
  text-indent: 10px;
  height: 24px;
  outline: none;
  ::placeholder {
    font-size: 14px;
  }
`

const LeftRoundedInput = styled(SimpleInput)`
  border-width: 2px;
  border-color: ${Colors.neonCarrot};
  border-radius: 5px 0 0 5px;
  font-size: 18px;
  ::-webkit-input-placeholder {
    font-size: 18px;
  }
  ::-moz-placeholder {
    font-size: 18px;
  }
  :-ms-input-placeholder {
    font-size: 18px;
  }
  :-moz-placeholder {
    font-size: 18px;
  }
`

const TextAreaInput = styled.textarea`
  text-align: left;
  color: ${Colors.outerSpace};
  background-color: ${Colors.white};
  border: 1px solid ${Colors.darkIron};
  box-sizing: border-box;
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  font-size: 14px;
  padding-right: 10px;
  outline: none;
  ::placeholder {
    color: #bfbfbf;
    font-size: 14px;
  }
`
interface InputProps {
  inputType?: 'simple' | 'left-rounded'
  placeholder?: string
  value?: string
  error?: boolean
  height?: string
  width?: string
  lineHeight?: string
  readOnly?: boolean
}

interface DefaultInputProps extends InputProps {
  type?: string
  changed(e: React.ChangeEvent<HTMLInputElement>): void
  keyDown?(e: React.KeyboardEvent<HTMLInputElement>): void
  focused?(e: React.ChangeEvent<HTMLInputElement>): void
}

interface DefaultTextareaProps extends InputProps {
  rows?: number
  changed(e: React.ChangeEvent<HTMLTextAreaElement>): void
  clicked?(): void
  borderRadius?: string
}

type IDefaultInputProps = DefaultInputProps & PaddingProps & MarginProps & TextCustomProps

export const DefaultInput: React.FC<IDefaultInputProps> = (props: IDefaultInputProps) => {
  let Comp = SimpleInput
  let height = '38px'
  if (props.inputType === 'left-rounded') {
    Comp = LeftRoundedInput
    height = '55px'
  }
  return (
    <Comp
      readOnly={props.readOnly ? true : false}
      placeholder={props.placeholder}
      type={props.type || undefined}
      style={{
        ...getPaddingObj(props),
        ...getMarginObj(props),
        borderColor: props.error ? Colors.scarlet : undefined,
        width: props.width || '100%',
        height: props.height || height,
      }}
      value={props.value || ''}
      onChange={(e) => {
        e.preventDefault()
        props.changed(e)
      }}
      onKeyDown={(e) => {
        if (props.keyDown) {
          props.keyDown(e)
        }
      }}
      onFocus={(e) => {
        if (props.focused) {
          e.preventDefault()
          props.focused(e)
        }
      }}
    />
  )
}

type IDefaultTextareaProps = DefaultTextareaProps &
  PaddingProps &
  MarginProps &
  TextCustomProps &
  React.HTMLAttributes<HTMLElement>

export const DefaultTextarea: React.FC<IDefaultTextareaProps> = (props: IDefaultTextareaProps) => {
  const style = props.style ? props.style : {}
  return (
    <TextAreaInput
      placeholder={props.placeholder}
      rows={props.rows ? props.rows : 5}
      style={{
        ...getPaddingObj(props),
        ...getMarginObj(props),
        borderColor: props.error ? Colors.scarlet : Colors.darkIron,
        width: props.width ? props.width : '100%',
        lineHeight: props.lineHeight ? props.lineHeight : '20px',
        height: props.height ? props.height : '100px',
        borderRadius: props.borderRadius || '5px',
        ...style,
      }}
      value={props.value || ''}
      onChange={(e) => {
        e.preventDefault()
        props.changed(e)
      }}
      onClick={(e) => {
        e.preventDefault()
        if (props.clicked) props.clicked()
      }}
    />
  )
}

interface DefaultFormItemProps extends ComponentProps<'div'> {
  title?: string
  errorMsg?: string
  lineHeight?: string
  icon?: string | null
  promptTitle?: string
  size?: TextSizeOption
}

export const DefaultFormItem: React.FC<DefaultFormItemProps> = (props: DefaultFormItemProps) => {
  return (
    <div style={{ width: '100%', textAlign: 'left' }}>
      {props.title && (
        <div
          style={{
            lineHeight: '20px',
            paddingLeft: '5px',
            textAlign: 'left',
          }}
        >
          <FjText fontSize={props.size ? props.size : 'small'} color={props.color ? props.color : undefined}>
            {props.title}
            {props.icon ? (
              <Tooltip placement="bottomLeft" title={props.promptTitle} arrowPointAtCenter>
                <Icon src={props.icon} marginLeft={props.title ? '5px' : '0px'} />
              </Tooltip>
            ) : null}
          </FjText>
        </div>
      )}
      <div
        style={{
          lineHeight: props.lineHeight ? props.lineHeight : '38px',
          marginTop: '10px',
        }}
      >
        {props.children}
      </div>
      <div style={{ height: '20px', paddingLeft: '5px' }}>
        <FjText error={true}>{props.errorMsg}</FjText>
      </div>
    </div>
  )
}
