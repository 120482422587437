import React from 'react'
import { ContainerDiv } from 'src/components/Common/Cards'
import { FjAvatar } from 'src/components/Common/FjAvatar'
import { FjText } from 'src/components/Common/Text'
import { Colors } from 'src/constants/colors'
import { Paths } from 'src/constants/navigation'
import { Author } from 'src/models/Author'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { sharedDataStore } from 'src/store/DataStore'

type AuthorCreatedByProps = {
  author: Author
}

export const AuthorCreatedBy = ({ author }: AuthorCreatedByProps) => {
  const cannotAccessProfile = sharedDataStore.user.isFaasPublicOrPartner() || sharedDataStore.user.isAnonymous()
  const handleClickAuthor = () => {
    if (cannotAccessProfile) return
    sharedAppStateStore.navigate(Paths.getProfilePath(author.authorID))
  }

  return (
    <ContainerDiv
      display="flex"
      alignItems="center"
      gap={16}
      onClick={handleClickAuthor}
      cursor={cannotAccessProfile ? undefined : 'pointer'}
    >
      <FjAvatar size={38} author={author} />
      <ContainerDiv display="flex" flexDirection="column" alignItems="start" textAlign="left">
        <FjText fontSize="12px" fontWeight="bold500" color={Colors.tapa}>
          Created by
        </FjText>
        <FjText fontSize="12px" fontWeight="bold" color={Colors.shark}>
          {author.fullName}
        </FjText>
      </ContainerDiv>
    </ContainerDiv>
  )
}
