import React from 'react'
import { Col, Row } from 'antd'
import { Formik } from 'formik'
import { Form } from 'formik-antd'

import {
  FjFormItem,
  FormActionButtons,
  FormHeaderText,
  FjInput,
  FjEmailInput,
  FjSelect,
  GroupSelector,
  FjDatePicker,
} from 'src/components/Common'
import { combineValidations, isRequired, isEmail, isFullName } from 'src/utils/validation'
import { sharedAppStateStore } from 'src/store/AppStateStore'

import { User } from 'src/models/User'
import { UserSelect } from 'src/components/Feed/UserSelect'
import { sharedDataStore } from 'src/store/DataStore'
import { observer } from 'mobx-react'

interface InviteTeammateFormProps {
  user?: User
  onCancel?(): void
  onSuccess?(): void
}
@observer
export class InviteTeammateForm extends React.Component<InviteTeammateFormProps> {
  handleSubmit = async (data: any) => {
    try {
      const { accessRole, managerId, startDate } = data
      const newUser = this.props.user || new User()
      await sharedAppStateStore.wrapAppLoading(
        newUser.save(
          {
            ...data,
            accessRole: accessRole.trim(),
            managerId,
            startDate: startDate ? startDate.toISOString() : null,
          },
          true,
          true
        )
      )
      if (this.props.onSuccess) await this.props.onSuccess()
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  getInitialValues = () => {
    const user = this.props.user || new User()
    let {
      email,
      accessRole,
      fullName,
      manager,
      userSpecificGroupIds,
      department,
      organization,
      division,
      costCenter,
      startDate,
      locationCountry,
      locationState,
      jobPosition,
    } = user
    if (!userSpecificGroupIds.length && !sharedDataStore.user.isPartOfManyGroups()) {
      userSpecificGroupIds = [sharedDataStore.user.defaultGroup.id]
    }
    // Antd Select component is not working for empty value for its option, so we add space to fix.
    // When submitting, we apply trim method to send correct value.
    return {
      fullName,
      email,
      accessRole: `${accessRole} `,
      managerId: manager?.id,
      groupIds: userSpecificGroupIds,
      department,
      organization,
      division,
      costCenter,
      startDate,
      locationCountry,
      locationState,
      jobPosition,
    }
  }

  render() {
    const { user } = this.props
    return (
      <>
        <FormHeaderText heading={`${user ? 'Edit' : 'Add'} User`} />
        <Formik initialValues={this.getInitialValues()} onSubmit={this.handleSubmit} enableReinitialize>
          {({ values }) => (
            <Form>
              <Row gutter={[14, 0]}>
                <Col xs={24} md={12}>
                  <FjFormItem
                    fieldtitle="Full Name*"
                    name="fullName"
                    validate={combineValidations(isRequired, isFullName)}
                  >
                    <FjInput name="fullName" placeholder="Full Name" />
                  </FjFormItem>
                </Col>
                <Col xs={24} md={12}>
                  <FjFormItem fieldtitle="Email*" name="email" validate={combineValidations(isRequired, isEmail)}>
                    <FjEmailInput name="email" placeholder="Email" readOnly={!!user} />
                  </FjFormItem>
                </Col>
                <Col xs={24} md={12}>
                  <FjFormItem fieldtitle="Role" name="accessRole">
                    <FjSelect
                      name="accessRole"
                      options={[
                        { label: 'Standard', value: ' ' },
                        { label: 'Manager', value: 'manager ' },
                        { label: 'Admin', value: 'admin ' },
                        { label: 'Public', value: 'public ' },
                        { label: 'Partner', value: 'partner ' },
                      ]}
                    />
                  </FjFormItem>
                </Col>
                {!['public ', 'partner '].includes(values.accessRole) ? (
                  <Col xs={24} md={12}>
                    <FjFormItem fieldtitle="Manager" name="managerId">
                      <UserSelect
                        excludeUserIds={[user?.id]}
                        initialLabel={user?.manager?.fullName}
                        name="managerId"
                        placeholder="Manager"
                      />
                    </FjFormItem>
                  </Col>
                ) : null}
                <Col xs={24} md={12}>
                  <FjFormItem fieldtitle="Job Title" name="jobPosition">
                    <FjInput name="jobPosition" placeholder="Job Title" />
                  </FjFormItem>
                </Col>
                {!['public ', 'partner '].includes(values.accessRole) ? (
                  <>
                    <Col xs={24} md={12}>
                      <FjFormItem fieldtitle="Start Date" name="startDate">
                        <FjDatePicker
                          placeholder="Start Date"
                          name="startDate"
                          format={'MMM Do, YYYY'}
                          style={{ width: '100%' }}
                        />
                      </FjFormItem>
                    </Col>
                    <Col xs={24} md={12}>
                      <FjFormItem fieldtitle="Organization" name="organization">
                        <FjInput name="organization" placeholder="Organization" />
                      </FjFormItem>
                    </Col>
                    <Col xs={24} md={12}>
                      <FjFormItem fieldtitle="Department" name="department">
                        <FjInput name="department" placeholder="Department" />
                      </FjFormItem>
                    </Col>
                    <Col xs={24} md={12}>
                      <FjFormItem fieldtitle="Division" name="division">
                        <FjInput name="division" placeholder="Division" />
                      </FjFormItem>
                    </Col>
                    <Col xs={24} md={12}>
                      <FjFormItem fieldtitle="Cost Center" name="costCenter">
                        <FjInput name="costCenter" placeholder="Cost Center" />
                      </FjFormItem>
                    </Col>
                    <Col xs={24} md={12}>
                      <FjFormItem fieldtitle="State" name="locationState">
                        <FjInput name="locationState" placeholder="State" />
                      </FjFormItem>
                    </Col>
                    <Col xs={24} md={12}>
                      <FjFormItem fieldtitle="Country" name="locationCountry">
                        <FjInput name="locationCountry" placeholder="Country" />
                      </FjFormItem>
                    </Col>
                    {sharedDataStore.user.isPartOfManyGroups() ? (
                      <Col xs={24} md={12}>
                        <GroupSelector
                          name="groupIds"
                          retainOptions={[sharedDataStore.user.defaultGroup.id]}
                          fieldTitle="Group"
                        />
                      </Col>
                    ) : null}
                  </>
                ) : null}
              </Row>
              <FormActionButtons onCancel={this.props.onCancel} submitButtonLabel={user ? 'Save' : 'Send Invite'} />
            </Form>
          )}
        </Formik>
      </>
    )
  }
}
