import React, { useMemo } from 'react'
import { FilterDropdownButton } from 'src/components/Common/Button'
import FjPopover, { FjPopoverContent } from 'src/components/Common/FjPopover'
import { getContentCategoryTitle, LearningContentType } from 'src/utils/content'

type ContentTypeSelectProps = {
  value?: LearningContentType
  onChange: (contentType?: LearningContentType) => void
  onClear?: () => void
  contentTypes: LearningContentType[]
}

export const ContentTypeSelect: React.FC<ContentTypeSelectProps> = ({
  value,
  onChange,
  onClear,
  contentTypes,
}: ContentTypeSelectProps) => {
  const contentTypeOptions = useMemo(() => {
    return contentTypes.map((option) => ({
      label: getContentCategoryTitle(option).slice(0, -1),
      value: option,
      id: option,
      key: option,
    }))
  }, [contentTypes])

  const selectedOption = contentTypeOptions.find((option) => option.id === value)

  return (
    <FjPopover
      content={
        <FjPopoverContent
          options={contentTypeOptions}
          values={selectedOption ? [selectedOption] : []}
          addFilterOption={(option) => onChange(option.id as LearningContentType)}
        />
      }
    >
      <FilterDropdownButton
        selected={!!selectedOption}
        title={selectedOption ? `Content Type | ${selectedOption.label}` : 'Content Type'}
        onClear={onClear}
      />
    </FjPopover>
  )
}
